/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    FormDataConsumer,
    SelectField,
    DataProviderContext,
    AutocompleteArrayInput,
    useInput,
    LinearProgress,
} from 'react-admin';
import { connect } from 'react-redux';

import { OUSelectInput, setOU } from './organizationUnitSelector.component';
import { getDuplicates, validate, validateDate, validateDuplicates, validateHeight, validateIdNumber, validateNumber, validatePhoneNumber } from '../utils/validations';
import { ExerciseCategoriesComponent, ExerciseCategoryCheckbox, ExercisesByTreatmentProtocol, getLists } from './Generic/TreatmentProtocolExerciseData';
import { ChangesToDevice, choicesGender, ControlPatientOptions, DeviceSize, Relation, TreatmentTypes } from '../utils/bq_enums';
import { BQCheckbox, BQLabel, FunctionField, BQSimpleIterator, BQSelectInput } from './Generic/bq-form-components';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { bqAuthData } from '../utils/bq-auth';
import { bqBlue, useBQStyles } from '../themes';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { RefreshRounded } from '@material-ui/icons';
import { useForm } from 'react-final-form';
import { getTranslatedName } from '../utils/textUtils';
import { Button, CircularProgress } from '@material-ui/core';
import { startVideoMeeting } from '../utils/bq-zoom';


class PatientComponent extends React.Component {

    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = PatientCreate
        } else if (isEdit) {
            ComponentView = PatientEdit
        } else {
            ComponentView = PatientList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(PatientComponent);


const confirmationObject = [
    {
        title: `[subjectOrPatient] [idNumber] is set as a [controlPatient] subject`,
        text: `Are you sure you want to set this [subjectOrPatient] as [controlPatient] subject?`,
        condition: (bqForm, record) => bqForm.formData.controlPatient &&
            bqForm.formData.controlPatient != "UNSET" &&
            (!record.idNumber || !record.controlPatient || record.controlPatient === "UNSET")
    },
    {
        title: `Affected side of body for [subjectOrPatient] [idNumber] is set as to [treatmentType]`,
        text: `Are you sure you want to set affected side of the body for this [subjectOrPatient] as [treatmentType]?`,
        condition: (bqForm, record) => bqForm.formData.treatmentType && (!record.idNumber || !record.treatmentType)
    },
    {
        title: `Exercise Protocol Warning`,
        text: `We noticed that you don’t have exercises chosen for stage [value]. Are you sure you want to proceed?`,
        condition: (bqForm) => bqForm.formData.currentTreatmentProtocol?.treatmentStages?.
            filter(stage => !stage.formData.exerciseCategoriesForTreatmentStage?.
                some(cat => cat.selectedExercises.some(exercise => exercise.isEnabled)))?.
            map(stage => stage.name).reduce((acc, stage) => `${acc}, ${stage}`, '')
    },
    {
        title: `[subjectOrPatient] has completed treatment / discontinued`,
        text: `Are you sure that you want to mark [subjectOrPatient] [idNumber] as complete / discontinue? This [subjectOrPatient] will no longer appear in the BQ app`,
        condition: (bqForm, record) => bqForm.formData?.participationConcluded && !record.participationConcluded
    }
]

const PatientList = (props) => {
    const { isMarketTargetAudience } = bqAuthData
    const roleOtherThanRandomizer = bqAuthData.role.some(role => role.indexOf('Admin') !== -1)

    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => {
            setTimeout(() => setState({
                isLoadingDictionaries: false,
                cache: data
            }), 500)
        })
    }, [dataProvider]);

    return (
        <BQModelList {...props} disableDelete={true} nameField="idNumber" showDates={true}>
            <TextField label={`${isMarketTargetAudience ? 'Patient name' : 'Subject ID'}`} source="idNumber" />
            {roleOtherThanRandomizer && <SelectField label="Sex" source="gender" choices={choicesGender} />}
            <TextField source="organizationalUnit" label="Clinic" />
            {roleOtherThanRandomizer && (
                state?.cache && <FunctionField
                    label="Exercise protocol"
                    source="treatmentProtocolId"
                    value={(val) => {
                        return state.cache.treatmentProtocolsCache.find(item => item.id == val)?.name || 'N/A'
                    }} /> || <LinearProgress />)
            }
            {roleOtherThanRandomizer && <SelectField source="caregiverRelation" label="Nature of caregiver relationship" choices={Relation} />}
            {!isMarketTargetAudience && <FunctionField label="Experimental group selected" source="controlPatient" value={(v) => v?.toLowerCase() !== 'unset' ? '✓' : ''} />}
            <FunctionField label="Completed / Discontinued" source="participationConcluded" value={(v) => !!v ? '✓' : ''} />
        </BQModelList>
    )
}

const PatientEditor = (props) => {
    const bqForm = {}

    const { isMarketTargetAudience, subjectOrPatient } = bqAuthData

    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    const { isRandomizator, isAdmin, isSuperAdmin, isOperator, role } = bqAuthData

    const roleOtherThanRandomizer = role.some(role => role.indexOf('Randomizator') === -1)
    const displayTreatmentShamComponents = ((!props.record.idNumber || !props.record.controlPatient || props.record.controlPatient === 'UNSET') && isRandomizator)


    const canEditTreatment = isAdmin || isOperator

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: `A ${subjectOrPatient?.toLowerCase()}`,
        duplicates: props.duplicates
    }

    const { treatmentProtocolId, controlPatient, treatmentType } = props?.record || {}
    const displayVideoButton = treatmentProtocolId && controlPatient === 'SET' && treatmentType && isOperator
    return (
        <SimpleForm {...props} warnWhenUnsavedChanges autoComplete="off" toolbar={<BQToolbar type={subjectOrPatient} bqForm={bqForm} nameField="idNumber" confirmation={confirmationObject} />}>
            {displayVideoButton && <VideoMeetingButton />}
            <FormDataConsumer>
                {({ formData }) => {
                    bqForm.formData = formData;
                }}
            </FormDataConsumer>
            <BQSideBySide>
                <BQSection title={`${isMarketTargetAudience ? 'Patient' : 'Subject'} Info`} readOnly={!isAdmin}>
                    <BQInput
                        label={`${isMarketTargetAudience ? 'Patient name' : 'Subject ID'}`}
                        placeholder={`${isMarketTargetAudience ? '' : 'Site ID-Subject ID (XXX-YY)'}`}
                        source="idNumber"
                        validate={isMarketTargetAudience ? validate : validateIdNumber}
                        duplicates={duplicatesCheckData}
                        readOnly={!!props.record.idNumber && !(isSuperAdmin || isAdmin)} />
                    {isMarketTargetAudience &&
                        <>
                            <BQInput label="Date of birth" source="dateOfBirth" type="date" validate={validateDate} />
                            <BQInput label="ID number" source="idOrPassport" placeholder="ID number/Passport number" validate={validate} />
                            <BQInput type="phoneNumber" label="Phone number" source="phoneNumber" validate={validatePhoneNumber} placeholder="Phone number with country code" />
                        </>
                    }
                    {roleOtherThanRandomizer &&
                        <>
                            <BQDropDown
                                label="Sex"
                                source="gender"
                                choices={choicesGender}
                                validate={validate}
                                unsorted
                            />
                            <BQInput source="height" placeholder={isMarketTargetAudience ? 'In cm (for example: 180)' : 'Feet’Inches (for example: 5’2)'} validate={validateHeight} {...(isMarketTargetAudience ? { maxLength: 3 } : {})} />
                            {isSuperAdmin && <OUSelectInput label="Clinic" />}
                        </>
                    }
                </BQSection>


                {roleOtherThanRandomizer &&
                    <>
                        <BQSection title="Caregiver Info" readOnly={!isAdmin}>
                            <BQDropDown source="caregiverRelation" label="Nature of caregiver relationship" choices={Relation} unsorted />
                        </BQSection>
                        {isAdmin && <BQCheckbox label={`${subjectOrPatient} has completed treatment / discontinued`} source="participationConcluded" />}
                    </>
                }
            </BQSideBySide>
            <BQSideBySide>
                {(roleOtherThanRandomizer || displayTreatmentShamComponents) && <BQSection title={`Treatment Info ${isEnglish ? '' : ' - ' + langName}`} readOnly={!isAdmin}>
                    {roleOtherThanRandomizer &&
                        <>
                            <BQDropDown source="deviceSize" choices={DeviceSize} unsorted />

                            <FormDataConsumer>
                                {({ formData, scopedFormData }) => {
                                    const { deviceInfo } = formData || scopedFormData
                                    let dropDown = false
                                    if (deviceInfo && (!ChangesToDevice.some(item => item.name === deviceInfo) || deviceInfo.indexOf('Other') !== -1)) {
                                        dropDown = false
                                    } else {
                                        dropDown = true
                                    }
                                    return <>
                                        <BQInput label="Changes in device" source="deviceInfo" multiline visible={!dropDown} readOnly={!isAdmin} />
                                        <BQDropDown label="Changes in device" source="deviceInfo" choices={ChangesToDevice} unsorted visible={dropDown} readOnly={!isAdmin} />
                                    </>
                                }}
                            </FormDataConsumer>
                        </>

                    }


                    {!isMarketTargetAudience && displayTreatmentShamComponents && <BQDropDown source="controlPatient" label="Treatment/Sham group" choices={ControlPatientOptions} unsorted readOnly={false} validate={validate} />}

                    {roleOtherThanRandomizer &&
                        <>
                            {(props.record.idNumber && props.record.treatmentType) && <BQInput source="treatmentType" label="Affected side of the body" readOnly /> || <BQDropDown source="treatmentType" label="Affected side of the body" choices={TreatmentTypes} unsorted validate={validate} />}
                            <ReferenceInput
                                perPage={1000}
                                label="Exercise protocol"
                                source="treatmentProtocolId"
                                reference="treatmentProtocols">
                                {isAdmin ?
                                    <BQSelectInput source="treatmentProtocolId" validate={validate} allowDuplicates={true} />
                                    :
                                    <BQDropDown source="treatmentProtocolId" validate={validate} allowDuplicates={true} readOnly={true} />
                                }

                            </ReferenceInput>
                            <ExercisesByTreatmentProtocol {...props} source="treatmentProtocolId" />
                        </>
                    }
                </BQSection>
                }
                <></>
            </BQSideBySide>

            {roleOtherThanRandomizer && <ExerciseCategoriesComponent {...props} treatmentStagesContainer="currentTreatmentProtocol">
                <BQSimpleIterator {...props} source="currentTreatmentProtocol.treatmentStages">
                    <BQLabel label={(item) => getTranslatedName(item, langCode)} />
                    <BQLabel label="Stage Duration: [source] minutes" source="treatmentStageDuration" variant="body2" />
                    <BQLabel label="Stage Type: [source]" source="type" variant="body2" />
                    <BQSimpleIterator {...props} source="formData.exerciseCategoriesForTreatmentStage">
                        <div style={{ padding: '24px' }}>
                            <ExerciseCategoryCheckbox {...props} langCode={langCode} readOnly={!canEditTreatment} />
                        </div>

                        <table style={{ width: '1200px', marginLeft: '48px', borderCollapse: 'collapse' }}>
                            <tr style={headerStyle}>
                                <td style={tableCellWidth(300)}><BQLabel style={titleStyle} label="Name" variant="body2" /></td>
                                <td style={numberCell}><BQLabel style={titleStyle} label="Sets" variant="body2" /></td>
                                <td style={numberCell}><BQLabel style={titleStyle} label="Repetitions" variant="body2" /></td>
                                <td style={tableCellWidth(16)}></td>
                                <td><BQLabel style={titleStyle} label="Progressions" variant="body2" /></td>
                                <td style={tableCellWidth(160)}></td>
                            </tr>
                            <BQSimpleIterator {...props} source="selectedExercises" >
                                <ExerciseComponent {...props} />
                            </BQSimpleIterator>
                        </table>

                    </BQSimpleIterator>
                </BQSimpleIterator>
            </ExerciseCategoriesComponent>}
        </SimpleForm>
    )
}

const titleStyle = {
    padding: '16px 16px 16px 8px',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.8)'
}

const headerStyle = {
    fontSize: '16px',
    borderRight: '0px',
    borderLeft: '0px',
    boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.1)',
    borderSpacing: 0,
    verticalAlign: 'top'
}


const tableCellWidth = (width) => {
    return {
        maxWidth: `${width}px`,
        minWidth: `${width}px`,
        width: `${width}px`
    }
}
const numberCell = tableCellWidth(96)


const ExerciseComponent = (props) => {
    const bqClasses = useBQStyles()
    const inputValue = useInput(props)?.input?.value

    const form = useForm()
    const { canEditTreatment, language } = props
    const { code: langCode } = language || {}

    const parseSource = (sourceName) => {
        return `${props.source}${sourceName ? `.${sourceName}` : ''}`
    }
    const undefinedOrNull = (val) => {
        return val === undefined || val === null
    }
    const createChoices = (numbers) => numbers.map(num => {
        return { name: `${num}`, id: `${num}` }
    })

    const { numOfSets, numOfRepetitions, progressionsList, secsInsteadOfReps, availableProgressions } = inputValue || {}
    const secsRepetition = secsInsteadOfReps ? '(Seconds)' : ''
    const repsChoices = createChoices(secsInsteadOfReps ? [10, 20, 30, 40, 50, 60] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
    const isDefault = undefinedOrNull(numOfSets) && undefinedOrNull(numOfRepetitions) && undefinedOrNull(progressionsList)

    return (
        <tr className={bqClasses.exerciseForPatientTableRow}>
            <td><div className={bqClasses.exerciseForPatientContent}><BQCheckbox variant={'caption'} source={parseSource('isEnabled')} label={(item) => getTranslatedName(item, langCode)} readOnly={!canEditTreatment} /></div></td>
            <td><div className={bqClasses.exerciseForPatientContent}><BQDropDown label="" source={parseSource('numOfSets')} borderless defaultValueSource={parseSource('orgNumOfSets')} choices={createChoices([1, 2, 3, 4, 5])} /></div></td>
            <td><div className={bqClasses.exerciseForPatientContent}><BQDropDown label="" source={parseSource('numOfRepetitions')} borderless defaultValueSource={parseSource('orgNumOfRepetitions')} choices={repsChoices} /></div></td>
            <td><div style={{ fontSize: '11px' }}>{secsRepetition}</div></td>
            <td>
                <div className={bqClasses.exerciseForPatientContent} style={{ paddingTop: '24px' }} >
                    <AutocompleteArrayInput id={`${parseSource('progressionsList')}_input`} choices={availableProgressions} source={parseSource('progressionsList')} label="" optionValue="id" variant="outlined" optionText={(item) => getTranslatedName(item, langCode)} />
                </div>
            </td>
            <td onClick={() => {
                form.change(parseSource('progressionsList', undefined))
                form.change(parseSource('numOfSets', undefined))
                form.change(parseSource('numOfRepetitions', undefined))
            }}>
                <div className={bqClasses.exerciseForPatientContent} style={{ color: bqBlue, marginTop: '-8px' }}>
                    {!isDefault && <table style={{ cursor: 'pointer' }}><tr><td><RefreshRounded style={{ display: 'inline-block', transform: 'scaleX(-1)', fontSize: '24px' }} /></td><td><BQLabel variant="body2" label={'Switch to Default'} style={{ color: bqBlue }} /></td></tr></table>}
                </div>
            </td>
        </tr>
    )
}

const VideoMeetingButton = (props) => {
    const dataProvider = useContext(DataProviderContext);
    const [loadingMeetingData, setLoadingMeetingData] = useState(false)

    return <div style={{ position: 'fixed', left: '50%', top: '48px', zIndex: 99999 }}>
        <Button
            id="btStartMeeting"
            className="MuiButton-contained"
            style={{
                boxShadow: 'none'
            }}
            onClick={(e) => {
                if (loadingMeetingData) {
                    return
                }
                setLoadingMeetingData(true)
                dataProvider.getOne('zoomMeetingDataa', {
                    id: {
                        patientId: props.record?.id,
                        timezoneOffset: new Date().getTimezoneOffset() / -60.0
                    }
                })
                    .then(response => {
                        const meetingData = response?.data;
                        if (meetingData?.meetingNumber) {
                            startVideoMeeting({
                                sdkKey: meetingData.zoomSDKKey,
                                signature: meetingData.zoomSDKToken,
                                meetingNumber: meetingData.meetingNumber,
                                password: meetingData.meetingPassword,
                                userName: `${bqAuthData.name} (Operator)`,
                                zak: meetingData.zoomToken
                            })
                        } else {
                            console.error(response)
                            alert('Could not initiate a video meeting. If this problem persists please contact BrainQ support')
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        alert('Could not initiate a video meeting. If this problem persists please contact BrainQ support')
                    })
                    .finally(() => setLoadingMeetingData(false))
            }}>
            <div style={{ background: loadingMeetingData ? 'none' : 'url(\'./icons/video_meeting.svg\')', width: 24, height: 24 }}>
                {loadingMeetingData && <CircularProgress style={{ width: '16px', height: '16px', marginTop: '2px' }} />}
            </div>&nbsp;START A MEETING
        </Button>
    </div >
}

const patientDataTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Patient', data, ['idNumber']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    const treatmentStages = data.currentTreatmentProtocol?.treatmentStages.map((stage, stageIndex) => {
        const stageToSave = { ...stage }
        delete stageToSave.nameTranslations
        const translatedStage = {
            ...stageToSave,
            index: stageIndex,
            exercisesCategories: stage.formData.exerciseCategoriesForTreatmentStage.map((category, categoryIndex) => {
                const selectedExercisesIndexed = category.selectedExercises.map((exercise, exerciseIndex) => {
                    return {
                        index: exerciseIndex,
                        isEnabled: exercise.isEnabled,
                        exerciseId: exercise.exerciseId,
                        numOfRepetitions: exercise.numOfRepetitions && parseInt(exercise.numOfRepetitions) || null,
                        numOfSets: exercise.numOfSets && parseInt(exercise.numOfSets) || null,
                        progressionsList: exercise.progressionsList?.length && exercise.progressionsList || null
                    }
                })
                return {
                    index: categoryIndex,
                    exerciseCategoryId: category.exerciseCategoryId,
                    selectedExercisesIndexed
                }
            })
        }
        delete translatedStage.name
        delete translatedStage.formData
        return translatedStage;
    })

    setOU(data)
    delete data.treatments;
    delete data.treatmentStagesData;
    delete data.treatmentProtocol;
    delete data.currentTreatmentProtocol;
    delete data.totalSessionTime;
    delete data.organizationalUnit_scan;

    if (data?.controlPatient?.toLowerCase() === 'set') {
        delete data.controlPatient
    }
    if (treatmentStages) {
        data.treatmentStages = treatmentStages;
    }
    data.deletedAt = data.deletedAt || 0;
    Object.keys(data).filter(key => key.match(/_scan/)).forEach(key => delete data[`${key}`])
    return data
}

const PatientMutate = (isCreate, props) => {
    const { language } = props
    const [duplicates, setDuplicates] = useState();
    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => {
            setTimeout(() => setState({
                isLoadingDictionaries: false,
                cache: data
            }), 500)
        })
    }, [dataProvider]);

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => patientDataTransform(data, setDuplicates) }
    if (isCreate) {
        editorProps.record = { controlPatient: 'UNSET' }
    }
    return (
        <div>
            {isCreate ?
                (<Create {...editorProps}>
                    <PatientEditor language={language} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...editorProps} >
                    <PatientEditor language={language} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const PatientCreate = (props) => PatientMutate(true, props)

const PatientEdit = (props) => PatientMutate(false, props)

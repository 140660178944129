// In theme.js
import { createMuiTheme, defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import createPalette from '@material-ui/core/styles/createPalette';
import { makeStyles } from '@material-ui/core';

const bqBorderBottom = 'solid 2px #51d4d9'

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: '#ff0266', // Not far from red
        },
        secondary: {
            main: '#00ba00', // Not far from green
        },
    })
);

const typography = {
    fontFamilySecondary: '\'Poppins\', sans-serif',
    fontFamily: 'Geomanist Regular',
    fontSize: 16, // Should be a number in pixels
    fontStyle: 'normal',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: palette.text.primary,
};

const typographyBase = {
    fontFamily: typography.fontFamily,
    fontSize: typography.fontSize,
    fontStyle: typography.fontStyle,
    color: typography.color,
};

const typographyHeader = {
    ...typographyBase,
    fontWeight: typography.fontWeightBold,
    fontFamily: typography.fontFamilySecondary, // Use a dedicated font for the header
};

const typographyBody = {
    ...typographyBase,
    fontWeight: typography.fontWeightRegular,
    fontFamily: typography.fontFamily,
};


const normalTextSize = '14px';
const textColor = '#2b3b4f'

const bqInputBorder = {
    border: 'solid 1px rgba(193, 201, 206, 0.3)',
    borderRadius: '3px',
    overflow: 'hidden'
}

export const bqBlue = 'rgba(81, 212, 217, 1)'

export const bq_theme = createMuiTheme(
    merge({}, defaultTheme, {
        '&:#filter_input': {
            paddingLeft: '64px'
        },
        fontFamily: 'Geomanist Regular',
        palette: {
            // Your theme goes here
            // Write the following code to have an orange app bar. We'll explain it later in this article.
            secondary: {
                main: '#FFFFFF', // Not far from orange
            },
        },
        PhoneInputCountryIconUnicode: {
            fontSize: '24px'
        },
        overrides: {
            MuiAppBar: {
                positionFixed: {
                    zIndex: 1,
                    boxShadow: 'none'
                }
            },
            RaAppBar: {
                title: {
                    marginTop: '48px',
                    marginLeft: '224px',
                    color: '#000000'
                },
            },
            RaSidebar: {
                root: {
                    position: 'fixed',
                    color: 'white',
                    zIndex: 1000,
                    top: 0,
                    bottom: '0px',
                    paddingTop: '64px',
                    background: 'url(\'./brainq-logo.svg\') black no-repeat 44px 32px',
                    backgroundSize: '120px 26px',
                    width: '208px'
                },
            },
            RaNotification: {
                success: {
                    color: 'white',
                    backgroundColor: bqBlue
                }
            },
            RaMenuItemLink: {
                backgroundColor: 'black',
                root: {
                    marginLeft: '0px',
                    paddingLeft: '28px',
                    color: 'white',
                    width: '208px',
                    '&:hover': {
                        color: 'white',
                        background: 'url(\'./icons/icons-menu-hover.svg\') no-repeat rgba(255, 255, 255, 0.2) 0px 0px',
                    },
                },
                active: {
                    color: 'white',
                    backgroundColor: 'rgba(81, 212, 217, 0.4)',
                    background: 'url(\'./icons/icons-menu-selected.svg\') black no-repeat 0px 0px',
                    '&:hover': {
                        color: 'white',
                        backgroundColor: 'rgba(81, 212, 217, 0.3)',
                        background: 'url(\'./icons/icons-menu-selected.svg\') black no-repeat 0px 0px',
                    },
                },
                icon: {
                    display: 'none'
                }
            },
            RaAutocompleteSuggestionItem: {
                root: {
                    fontSize: '14px',
                    borderBottom: 'solid thin rgba(0, 0, 0, 0.1)'
                }
            },
            RaLayout: {
                root: {
                    zIndex: 0
                },
                contentWithSidebar: {
                    backgroundColor: 'white'
                },
                content: {
                    marginLeft: '280px',
                    marginRight: '24px',
                    marginTop: '48px',
                    backgroundColor: 'white'
                }
            },
            MuiCard: {
                root: {
                    overflow: 'visible !important'
                }
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: 'none'
                }
            },
            PrivateNotchedOutline: {
                legendNotched: {
                    display: 'none'
                }
            },
            MuiInputBase: {
                root: bqInputBorder,
                transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            },
            MuiOutlinedInput: {
                root: {
                    border: 'none',
                    padding: '0px 12px 0px 12px'
                },
                adornedEnd: {
                    padding: 0,
                },
                inputMultiline: {
                    padding: '0 !important'
                },
                notchedOutline: {
                    border: 'solid thin',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    marginTop: '6px'
                },
            },
            MuiInputLabel: {
                root: {
                    display: 'none'
                }
            },
            MuiButton: {
                root: {
                    fontSize: '14px'
                },
                containedPrimary: {
                    color: 'white !important',
                    backgroundColor: '#51d4d9',
                    '&:hover': {
                        backgroundColor: '#51d4d9',
                        color: 'black !important',
                        boxShadow: 'none',
                    },
                }
            },
            MuiIconButton: {
                colorInherit: {
                    //color: 'black'
                }
            },
            RaAutocompleteInput: {
                suggestionsContainer: {
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                }
            },
            MuiChip: {
                root: {
                    height: '18px'
                },
                label: {
                    paddingLeft: '6px',
                    paddingRight: '10px',
                    fontSize: '14px'
                },
                deleteIcon: {
                    width: '16px'
                }
            },
            RaAutocompleteArrayInput: {
                suggestionsContainer: {
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                },
                chipContainerOutlined: {
                    margin: 0
                }
            },
            MuiSelect: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                }
            },
            MuiFilledInput: {
                root: {
                    border: bqInputBorder,
                    borderColor: 'rgba(0,0,0,.2)',
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                    },
                    background: 'none',
                    backgroundColor: 'none',
                    '&:hover': {
                        background: 'none',
                        backgroundColor: 'none',
                        border: 'solid 1px black'
                    },
                    '&:focus': {
                        background: 'none',
                        backgroundColor: 'none',
                        border: 'solid 1px black'
                    },
                },
                inputMarginDense: {
                    paddingTop: 12,
                },
                suggestionsContainer: {
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                },
                underline: {
                    '&:hover': {
                        border: 'solid 1px black',
                        '&:before': {
                            borderBottom: 'none',
                        }
                    },
                    '&:before': {
                        borderBottom: 'none',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                }
            },
            RaDatagrid: {
                thead: {
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',

                },
                headerCell: {
                    fontSize: normalTextSize,
                    fontWeight: 'bold',
                    color: textColor,
                    lineHight: '1.33em'
                },
                rowCell: {
                    fontSize: normalTextSize,
                    color: textColor,
                },
            },
            RaButton: {
                button: {
                    width: '112px',
                    height: '40px',
                    padding: '11px 34px',
                    borderRadius: '3px',
                    border: 'solid 1px rgba(193, 201, 206, 0.3)',
                    fontSize: '14px',
                    textAlign: 'center',
                    color: textColor,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        boxShadow: 'none',
                    },
                },
                smallIcon: {
                    display: 'none'
                },
            },
            RaSaveButton: {
                button: {
                    width: '113px',
                    height: '40px',
                    margin: '0 0 0 16px',
                    padding: '11px 41px 11px 42px',
                    borderRadius: '3px',
                    backgroundColor: '#51d4d9',
                    boxShadow: 'none',
                    '&:hover': {
                        width: '113px',
                        height: '40px',
                        margin: '0 0 0 16px',
                        padding: '11px 41px 11px 42px',
                        borderRadius: '3px',
                        backgroundColor: '#51d4d9',
                        boxShadow: 'none',
                    },
                },
                icon: {
                    display: 'none'
                },
            },
            MuiTypography: {
                body2: {
                    fontSize: normalTextSize
                }
            },
            MuiFormControl: {
                marginDense: {
                    margin: 0,
                    width: '100%'
                }
            },
            RaSimpleFormIterator: {
                line: {
                    borderBottom: 'none'
                },
                index: {
                    fontSize: normalTextSize,
                    marginTop: '6px'
                },
                form: {
                    flex: 'none',
                    marginTop: '-8px'
                },
                action: {
                    paddingTop: '14px'
                },
                valueLabel: {
                    padding: 0,
                    margin: 0,
                    marginTop: 0
                }
            },
            MuiCheckbox: {
                root: {
                    padding: '9px 8px 9px 0px'
                }
            }
        },
        typography: {
            ...typographyBase,
            h1: {
                ...typographyHeader,
                textTransform: 'uppercase',
                fontSize: '4rem',

            },
            // ... Put other title styles below
            body1: {
                ...typographyBody,
                fontSize: '1rem',
            }
        },
        // textInputStyle: {
        //     fontSize: 12,
        //     color: textColor,
        //     border: 'solid 1px rgba(193, 201, 206, 0.3)',
        //     padding: '9px 12px 9px 12px',
        //     borderRadius: '3px',
        //     resize: 'none',
        //     verticalAlign: 'middle'
        // },
        // inputLabel: {
        //     fontSize: 12,
        //     color: '#adb6bb',
        //     height: '14px',
        //     width: '256px',
        //     verticalAlign: 'middle'
        // }
    })
);

export const useBQStyles = makeStyles(
    theme => ({
        root: {
            padding: 0,
            marginBottom: 0,
            '& > li:last-child': {
                borderBottom: 'none',
            },
        },
        BQSectionHeader: {
            fontSize: '14px',
            fontWeight: 'bold',
            paddingBottom: '8px',
            paddingTop: 40,
            borderBottom: bqBorderBottom,
        },
        BQLine: {
            borderBottom: bqBorderBottom
        },
        BQSectionContainer: {
            maxWidth: '640px'
        },
        BQSectionContent: {
            width: '100%'
        },
        textInputContainer: {
            clear: 'both',
            width: '100%',
            //padding: '21px 16px 21px 0',
            padding: 0,
            //boxShadow: 'inset 0 -1px 0 0 #e1e2e3',
            verticalAlign: 'top'
        },
        pointer: {
            cursor: 'pointer'
        },
        checkbox: {
            width: '32px'
        },
        line: {
            display: 'flex',
            listStyleType: 'none',
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down('xs')]: { display: 'block' },
            '&.fade-enter': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
            },
            '&.fade-enter-active': {
                opacity: 1,
                transform: 'translateX(0)',
                transition: 'all 500ms ease-in',
            },
            '&.fade-exit': {
                opacity: 1,
                transform: 'translateX(0)',
            },
            '&.fade-exit-active': {
                opacity: 0.01,
                transform: 'translateX(100vw)',
                transition: 'all 500ms ease-in',
            },
        },
        index: {
            width: '3em',
            paddingTop: '1em',
            [theme.breakpoints.down('sm')]: { display: 'none' },
        },
        form: { flex: 2 },
        inputLabel: {
            width: '33%',
            fontSize: normalTextSize,
            color: '#adb6bb',
            verticalAlign: 'top',
            paddingTop: '30px'
        },
        textInput: {
            fontSize: normalTextSize,
            color: textColor,
            padding: '9px 0px 9px 0px',
            verticalAlign: 'top',
            borderRadius: '3px',
        },
        bqInputLabel: {
            fontSize: normalTextSize,
            color: textColor,
            padding: '0px 0px 0px 0px',
            verticalAlign: 'middle',
        },
        bqInputValue: {
            minWidth: '97%',
            fontFamily: 'inherit',
            fontSize: normalTextSize,
            color: textColor,
            marginTop: '8px',
            padding: '4px ',
            maxWidth: '97%',
            ...bqInputBorder,
            lineHeight: '26px',
            backgroundColor: 'white',
            '&:hover': {
                border: 'solid thin rgb(0,0,0)',
            }
        },
        bqInputValueBorderless: {
            background: 'url(\'/icons/icons-arrow-down.svg\') no-repeat',
            backgroundSize: '24px 24px',
            backgroundPositionX: '70%',
            backgroundPositionY: '50%',
            fontSize: normalTextSize,
            color: textColor,
            width: '100%',
            lineHeight: '26px',
            '&:hover': {
                border: 'none',
            }
        },
        bqInputValueReadOnly: {
            paddingTop: '22px'
        },
        bqInputValueError: {
            border: 'solid thin red',
        },
        valueLabel: {
            fontSize: normalTextSize,
            color: textColor,
            marginTop: '8px',
            padding: '12px 9px 9px 0px',
            borderRadius: '3px',
        },
        dropdownInputStyle: {
            fontSize: normalTextSize,
            width: '100%',
            color: textColor,
            border: 'none',
            padding: '12px 9px 9px 12px',
            borderRadius: '3px',
            resize: 'none',
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        action: {
            paddingTop: '0.5em',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        toolbarContainer: {
            position: 'fixed',
            background: 'white',
            left: '208px',
            top: 0,
            right: 0,
            zIndex: 10000,
            boxShadow: '0 2px 14px 0 rgba(113, 113, 113, 0.14)',
            height: '136px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        },
        toolbarFormButtonsContainer: {
            width: '100%',
            textAlign: 'right',
        },
        toolbarMinimumCell: {
            padding: '0px 16px'
        },
        toolbarContentCell: {
            width: '100%',
            textAlign: 'left',
            fontSize: '24px',
            padding: 0,
        },
        backButton: {
            cursor: 'pointer',
            margin: 0,
            padding: '24px 16px 5px 16px',

            borderRadius: '3px',
            border: 'none',
            display: 'inline',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.1)',
            }
        },
        addButton: {
            ...iconButtonStyle('icons-add'),
            marginLeft: '28px',
            marginTop: '-24px'
        },
        editButton: {
            ...iconButtonStyle('icons-edit'),
        },
        removeButton: {
            ...iconButtonStyle('icons-remove')
        },
        logoutButton: {
            ...iconButtonStyle('icons-logout'),
            position: 'fixed',
            bottom: 34,
            left: 168,
            zIndex: 100002
        },
        userInfoSection: {
            color: 'white',
            position: 'fixed',
            zIndex: 10001,
            width: 168,
            maxWidth: 168,
            height: 96,
            bottom: 0,
            verticalAlign: 'middle',
            alignItems: 'center',
            textAlign: 'center',
            overflow: 'hidden'
        },
        centerContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
        },
        sideBySideTable: {
            width: '100%',
        },
        sideBySideTableColumn: {
            verticalAlign: 'top'
        },
        dropdownContainer: {
            ...bqInputBorder,
            background: 'white',
            zIndex: 1000000,
            position: 'absolute',
            left: 0,
            right: 0,
            minWidth: '72px',
            maxHeight: '256px',
            overflowY: 'auto',
            overflowX: 'hidden',
            boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.2)',
        },
        dropdownItem: {
            cursor: 'pointer',
            minWidth: '60px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderBottom: 'solid thin rgba(0, 0, 0, 0.1)',
            lineHeight: '48px',
            position: 'relative',
            '&:hover': {
                background: 'rgb(240, 240, 240)',
            }
        },
        dropdownDefaultIcon: {
            border: `solid thin ${bqBlue}`,
            color: bqBlue,
            position: 'absolute',
            marginLeft: '8px',
            padding: '4px 4px 0px 4px',
            lineHeight: '14px',
            top: '13px',
            right: '8px',
            borderRadius: '4px'
        },
        dropdownArrowIcon: {
            cursor: 'pointer',
            fontSize: '24px',
            position: 'absolute',
            left: '48px',
            top: '2px'
        },
        dropdownClearIcon: {
            cursor: 'pointer',
            fontSize: '16px',
            color: 'rgba(0,0,0,0.5)',
            position: 'absolute',
            right: '16px',
            top: '18px'
        },
        exerciseForPatientTableRow: {
            borderBottom: 'solid thin rgba(0, 0, 0, 0.2)',
        },
        exerciseForPatientContent: {
            verticalAlign: 'top',
            marginTop: '-8px',
            marginBottom: '-8px',
            paddingLeft: '8px'
        },
        buttonReset: {
            boxShadow: 'none',
            textAlign: 'center',
            width: '84px',
            height: 'auto',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '5px',
            paddingBottom: '6px',
            marginLeft: '48px',
            fontSize: '14px'
        }
    }),
    { name: 'RaSimpleFormIterator' }
);

const iconButtonStyle = (iconName) => {
    return {
        display: 'inline-block',
        cursor: 'pointer',
        margin: 0,
        height: '32px',
        width: '32px',
        borderRadius: '3px',
        border: 'none',
        backgroundSize: '16px 16px',
        background: `url('./icons/${iconName}.svg') no-repeat 8px 8px`,
        '&:hover': {
            backgroundSize: '16px 16px',
            background: `url('./icons/${iconName}-hover.svg') no-repeat 8px 8px`,
        }
    }
}
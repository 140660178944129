/* eslint-disable react/display-name */
import { Amplify } from '@aws-amplify/core';
import React, { useState } from 'react';
import { Resource, SimpleForm } from 'react-admin';
import {
    AmplifyAdmin
} from 'react-admin-amplify';
import awsExports from './aws-exports';
import PatientComponent from './components/patient.component';
import TreatmentProtocolComponent from './components/treatmentProtocol.component';
import { CognitoUserCreate, CognitoUserEdit, CognitoUserList } from './components/cognitoUser.components';
import { OrganizationCreate, OrganizationEdit, OrganizationList } from './components/organization.component';
import ExercisesCategoryComponent from './components/exercisesCategory.component';
import ExerciseComponent from './components/exercises.component';
import TreatmentStageComponent from './components/treatmentStage.component';
import EquipmentComponent from './components/equipment.component';
import ProgressionComponent from './components/progression.component';
import { AuditTrailList } from './components/auditTrail.component'
import { bq_theme, useBQStyles } from './themes';
import { Auth } from 'aws-amplify';
import { bqAuthData, clearBQAuthData, setBQAuthData } from './utils/bq-auth';
import { DeviceCreate, DeviceEdit, DeviceList } from './components/device.component';

import langSelected from './redux/reducers/languageReducer'
import { LanguageSelection } from './components/Generic/bq-form-components';
import { LanguageCreate, LanguageEdit, LanguageList } from './components/language.component';
import { endVideoMeeting } from './utils/bq-zoom';

const queries = {
    ...require('./graphql/queries'),
    ...require('./extensions/queries'),
}

const mutations = {
    ...require('./graphql/mutations'),
}

Amplify.configure(awsExports); // Configure Amplify the usual way

const App = (props) => {
    const { checkAuthState } = props;
    const bqClasses = useBQStyles();



    const [userName, setUserName] = useState();

    Auth.currentAuthenticatedUser().then((autheticatedUser) => {
        setBQAuthData(autheticatedUser)
        setUserName(bqAuthData.name);
    })

    if (!bqAuthData.role) {
        return (
            <>Role is not set for current user</>
        )
    }

    const { userAllowed, isSuperAdmin, isAdmin, isAuditor } = bqAuthData
    return (

        <AmplifyAdmin
            authData={bqAuthData}
            theme={bq_theme}
            customReducers={{ langSelected }}
            logoutButton={null}
            refreshButton={null}
            operations={{ queries, mutations }}
            ready={!isAdmin && userAllowed && !isAuditor && window.location.href.indexOf('#/patients') === -1 && (window.location = '#/patients') || (!isAdmin && isAuditor && window.location.href.indexOf('#/audittrails') === -1 && (window.location = '#/audittrails'))}
            options={{
                enableAdminQueries: false
            }}>

            <Resource
                name="CognitoUsers"
                list={isAdmin && CognitoUserList}
                edit={isAdmin && CognitoUserEdit}
                create={isAdmin && CognitoUserCreate}
                options={{ label: 'Users' }}
            />

            <Resource
                name="languages"
                list={isSuperAdmin && LanguageList}
                create={isSuperAdmin && LanguageCreate}
                edit={isSuperAdmin && LanguageEdit}
            />

            <Resource
                name="patients"
                list={(isSuperAdmin || !isAuditor) && userAllowed && PatientComponent}
                create={(isSuperAdmin || !isAuditor) && userAllowed && PatientComponent}
                edit={(isSuperAdmin || !isAuditor) && userAllowed && PatientComponent}
                options={{ label: `${bqAuthData.isMarketTargetAudience ? 'Patients' : 'Subjects'}`, visible: false }}
            />

            <Resource
                name="organizationalUnits"
                list={isSuperAdmin && OrganizationList}
                create={isSuperAdmin && OrganizationCreate}
                edit={isSuperAdmin && OrganizationEdit}
                options={{ label: 'Clinics' }}
            />

            <Resource
                name="devices"
                list={isSuperAdmin && DeviceList}
                create={isSuperAdmin && DeviceCreate}
                edit={isSuperAdmin && DeviceEdit}
            />

            <Resource name="progressions"
                list={isSuperAdmin && ProgressionComponent}
                create={isSuperAdmin && ProgressionComponent}
                edit={isSuperAdmin && ProgressionComponent}
                options={{ label: 'Progression' }}
            />

            <Resource name="equipments"
                list={isSuperAdmin && EquipmentComponent}
                create={isSuperAdmin && EquipmentComponent}
                edit={isSuperAdmin && EquipmentComponent}
                options={{ label: 'Equipment' }}
            />

            <Resource name="exercises"
                list={isSuperAdmin && ExerciseComponent}
                create={isSuperAdmin && ExerciseComponent}
                edit={isSuperAdmin && ExerciseComponent}
                options={{ label: 'Exercises' }}
            />

            <Resource name="exercisesCategorys"
                list={isSuperAdmin && ExercisesCategoryComponent}
                create={isSuperAdmin && ExercisesCategoryComponent}
                edit={isSuperAdmin && ExercisesCategoryComponent}
                options={{ label: 'Exercise Categories' }}
            />

            <Resource name="treatmentStages"
                list={isSuperAdmin && TreatmentStageComponent}
                create={isSuperAdmin && TreatmentStageComponent}
                edit={isSuperAdmin && TreatmentStageComponent}
                options={{ label: 'Exercise Stages' }}
            />

            <Resource
                name="treatmentProtocols"
                list={isSuperAdmin && TreatmentProtocolComponent}
                create={isSuperAdmin && TreatmentProtocolComponent}
                edit={isSuperAdmin && TreatmentProtocolComponent}
                options={{ label: 'Exercise Protocols' }}
            />

            <Resource
                name="auditTrails"
                list={isAuditor && AuditTrailList}
                create={null}
                edit={null}
                options={{ label: 'Audit Trail' }}
            />

            <Resource
                name="auditTrailEventNames"
                list={null}
                create={null}
                edit={null}
                options={{ label: 'Audit Trail' }}
            />

            {!userAllowed && <div style={{ position: 'absolute', top: '50%', left: '50%', height: '64px', zIndex: '99999' }}>The Admin panel is not available for given user role</div> || <></>}

            <div style={{
                borderRadius: 5,
                backgroundColor: 'black',
                textColor: 'red !important',
                color: 'white',
                position: 'fixed',
                zIndex: 1300,
                width: 384,
                maxWidth: 192,
                bottom: 22,
                left: 10,
                verticalAlign: 'middle',
                alignItems: 'center',
                textAlign: 'left',
                display: isSuperAdmin ? 'block' : 'none'
            }}>
                <div style={{ marginLeft: 16, paddingTop: 16, marginBottom: -26 }}>Language</div>
                <SimpleForm toolbar={<></>}>
                    <LanguageSelection />
                </SimpleForm>

            </div>

            <div className={bqClasses.userInfoSection}>
                <div className={bqClasses.centerContent}>
                    {userName}
                </div>
            </div>
            <div id="logout_button" className={bqClasses.logoutButton} onClick={async () => {
                endVideoMeeting()
                await Auth.signOut()
                clearBQAuthData()
                checkAuthState();
            }}></div>
        </AmplifyAdmin>

    );
}


export default App
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser($input: CognitoUserModelInput!) {
    createCognitoUser(input: $input) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser($input: CognitoUserModelInput!) {
    updateCognitoUser(input: $input) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($input: IdInput!) {
    deleteCognitoUser(input: $input) {
      id
      email
      phoneNumber
      bqUsername
      role
      organizationalUnitId
      organizationalUnitName
      createDate
      lastModifiedDate
      isActive
      reset_password
      customAction
    }
  }
`;
export const createTreatmentOnServer = /* GraphQL */ `
  mutation CreateTreatmentOnServer($input: CreateTreatmentOnServerInput) {
    createTreatmentOnServer(input: $input) {
      id
      objectData
      patient {
        id
        idNumber
        idNumber_scan
        gender
        height
        controlPatient
        dateOfBirth
        idOrPassport
        phoneNumber
        treatmentProtocolId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
            }
          }
        }
        treatmentType
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        deviceSize
        deviceInfo
        caregiverName
        caregiverRelation
        numberOfTreatments
        recentTreatmentDate
        participationConcluded
        deletedAt
        updatedAt
        createdAt
      }
      treatmentsForPatient {
        id
        patientId
        sessionId
        organizationalUnitId
        organizationalUnit
        organizationalUnit_scan
        status
        eventLog {
          timestamp
          status
          emfState
          waveformIndex
          stageId
          categoryId
          exerciseId
        }
        notes
        timeIntoTreatment
        treatmentId
        waveformIndex
        durations
        createdAt
        startedAt
        completedAt
        deletedAt
        zoomUserId
        zoomMeetingId
        treatmentStages {
          index
          treatmentStageId
          treatmentStageDuration
          deviceEnabled
          type
          exercisesCategories {
            index
            exerciseCategoryId
            selectedExercisesIndexed {
              index
              isEnabled
              exerciseId
              numOfRepetitions
              numOfSets
              secsInsteadOfReps
              progressionsList
              equipmentList
            }
          }
        }
        categories {
          treatmentStageId
          exerciseCategoryId
          rating
        }
        exercises {
          treatmentStageId
          exerciseCategoryId
          exerciseId
          rating
          status
          notes
          startedAt
          elapsed
        }
        updateId
        updatedAt
        version
      }
      extra
    }
  }
`;
export const createOrganizationalUnit = /* GraphQL */ `
  mutation CreateOrganizationalUnit($input: OrganizationalUnitInput) {
    createOrganizationalUnit(input: $input) {
      id
      name
      name_scan
      facilityName
      organizationalUnit
      organizationalUnit_scan
      updatedAt
      deletedAt
      createdAt
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient($input: PatientInput) {
    createPatient(input: $input) {
      id
      idNumber
      idNumber_scan
      gender
      height
      controlPatient
      dateOfBirth
      idOrPassport
      phoneNumber
      treatmentProtocolId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      treatmentType
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deviceSize
      deviceInfo
      caregiverName
      caregiverRelation
      numberOfTreatments
      recentTreatmentDate
      participationConcluded
      deletedAt
      updatedAt
      createdAt
    }
  }
`;
export const createTreatmentStage = /* GraphQL */ `
  mutation CreateTreatmentStage($input: TreatmentStageInput) {
    createTreatmentStage(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      exerciseCategories
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExercisesCategory = /* GraphQL */ `
  mutation CreateExercisesCategory($input: ExercisesCategoryInput) {
    createExercisesCategory(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      exercises
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExercise = /* GraphQL */ `
  mutation CreateExercise($input: ExerciseInput) {
    createExercise(input: $input) {
      id
      name
      name_scan
      idInApp
      tip
      description
      nameTranslations {
        code
        text
      }
      tipTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      numOfRepetitions
      numOfSets
      secsInsteadOfReps
      illustrationFile
      progressionsList
      equipmentList
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment($input: EquipmentInput) {
    createEquipment(input: $input) {
      id
      imageUrl
      name
      name_scan
      idInApp
      description
      nameTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProgression = /* GraphQL */ `
  mutation CreateProgression($input: ProgressionInput) {
    createProgression(input: $input) {
      id
      name
      name_scan
      nameTranslations {
        code
        text
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTreatmentProtocol = /* GraphQL */ `
  mutation CreateTreatmentProtocol($input: TreatmentProtocolInput) {
    createTreatmentProtocol(input: $input) {
      id
      name
      name_scan
      numberOfTreatments
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment($input: CreateTreatmentInput) {
    createTreatment(input: $input) {
      id
      patientId
      sessionId
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
      }
      notes
      timeIntoTreatment
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
      }
      updateId
      updatedAt
      version
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: DeviceInput) {
    createDevice(input: $input) {
      id
      patientId
      serialNumber
      serialNumber_scan
      quickConnectNumber
      quickConnectNumber_scan
      macAddress
      macAddress_scan
      resistance
      deviceSize
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deletedAt
      updatedAt
      createdAt
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage($input: LanguageInput) {
    createLanguage(input: $input) {
      id
      code
      name
      name_scan
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const createConsent = /* GraphQL */ `
  mutation CreateConsent($input: ConsentInput) {
    createConsent(input: $input) {
      id
      type
      userId
      patientId
      timestamp
      dateTime
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganizationalUnit = /* GraphQL */ `
  mutation UpdateOrganizationalUnit($input: OrganizationalUnitInput) {
    updateOrganizationalUnit(input: $input) {
      id
      name
      name_scan
      facilityName
      organizationalUnit
      organizationalUnit_scan
      updatedAt
      deletedAt
      createdAt
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient($input: PatientInput) {
    updatePatient(input: $input) {
      id
      idNumber
      idNumber_scan
      gender
      height
      controlPatient
      dateOfBirth
      idOrPassport
      phoneNumber
      treatmentProtocolId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      treatmentType
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deviceSize
      deviceInfo
      caregiverName
      caregiverRelation
      numberOfTreatments
      recentTreatmentDate
      participationConcluded
      deletedAt
      updatedAt
      createdAt
    }
  }
`;
export const updateTreatmentStage = /* GraphQL */ `
  mutation UpdateTreatmentStage($input: TreatmentStageInput) {
    updateTreatmentStage(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      exerciseCategories
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateExercisesCategory = /* GraphQL */ `
  mutation UpdateExercisesCategory($input: ExercisesCategoryInput) {
    updateExercisesCategory(input: $input) {
      id
      name
      name_scan
      description
      nameTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      exercises
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateExercise = /* GraphQL */ `
  mutation UpdateExercise($input: ExerciseInput) {
    updateExercise(input: $input) {
      id
      name
      name_scan
      idInApp
      tip
      description
      nameTranslations {
        code
        text
      }
      tipTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      numOfRepetitions
      numOfSets
      secsInsteadOfReps
      illustrationFile
      progressionsList
      equipmentList
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProgression = /* GraphQL */ `
  mutation UpdateProgression($input: ProgressionInput) {
    updateProgression(input: $input) {
      id
      name
      name_scan
      nameTranslations {
        code
        text
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment($input: EquipmentInput) {
    updateEquipment(input: $input) {
      id
      imageUrl
      name
      name_scan
      idInApp
      description
      nameTranslations {
        code
        text
      }
      descriptionTranslations {
        code
        text
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTreatmentProtocol = /* GraphQL */ `
  mutation UpdateTreatmentProtocol($input: TreatmentProtocolInput) {
    updateTreatmentProtocol(input: $input) {
      id
      name
      name_scan
      numberOfTreatments
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment($input: UpdateTreatmentInput) {
    updateTreatment(input: $input) {
      id
      patientId
      sessionId
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
      }
      notes
      timeIntoTreatment
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
      }
      updateId
      updatedAt
      version
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: DeviceInput) {
    updateDevice(input: $input) {
      id
      patientId
      serialNumber
      serialNumber_scan
      quickConnectNumber
      quickConnectNumber_scan
      macAddress
      macAddress_scan
      resistance
      deviceSize
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      deletedAt
      updatedAt
      createdAt
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage($input: LanguageInput) {
    updateLanguage(input: $input) {
      id
      code
      name
      name_scan
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAppVersion = /* GraphQL */ `
  mutation UpdateAppVersion($input: AppVersionInput) {
    updateAppVersion(input: $input) {
      id
      deviceId
      appBuildNumber
      createdAt
      updatedAt
    }
  }
`;
export const startTreatment = /* GraphQL */ `
  mutation StartTreatment($input: ID) {
    startTreatment(input: $input) {
      id
      patientId
      sessionId
      organizationalUnitId
      organizationalUnit
      organizationalUnit_scan
      status
      eventLog {
        timestamp
        status
        emfState
        waveformIndex
        stageId
        categoryId
        exerciseId
      }
      notes
      timeIntoTreatment
      treatmentId
      waveformIndex
      durations
      createdAt
      startedAt
      completedAt
      deletedAt
      zoomUserId
      zoomMeetingId
      treatmentStages {
        index
        treatmentStageId
        treatmentStageDuration
        deviceEnabled
        type
        exercisesCategories {
          index
          exerciseCategoryId
          selectedExercisesIndexed {
            index
            isEnabled
            exerciseId
            numOfRepetitions
            numOfSets
            secsInsteadOfReps
            progressionsList
            equipmentList
          }
        }
      }
      categories {
        treatmentStageId
        exerciseCategoryId
        rating
      }
      exercises {
        treatmentStageId
        exerciseCategoryId
        exerciseId
        rating
        status
        notes
        startedAt
        elapsed
      }
      updateId
      updatedAt
      version
    }
  }
`;

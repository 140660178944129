/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Edit,
    TextField,
    Create,
    SimpleForm,
    ArrayInput,
    ReferenceInput,
    DataProviderContext
} from 'react-admin';

import { ExerciseCategoriesComponent, ExerciseCategoryCheckbox, getLists } from './Generic/TreatmentProtocolExerciseData';
import { getDuplicates, validate, validateDuplicates, validateNumber, validateString } from '../utils/validations';
import { useStyles } from '../utils/styles';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQCheckbox, BQLabel, BQSelectInput, BQSimpleIterator } from './Generic/bq-form-components';
import BQIterator from './Generic/BQIterator';
import { choicesTreatmentStageType } from '../utils/bq_enums';
import { BQModelList, BQSection, BQSideBySide, BQToolbar } from './Generic/BQUI';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { getTranslatedName } from '../utils/textUtils';

import { connect } from 'react-redux';

class TreatmentProtocolComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = TreatmentProtocolCreate
        } else if (isEdit) {
            ComponentView = TreatmentProtocolEdit
        } else {
            ComponentView = TreatmentProtocolList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(TreatmentProtocolComponent);

const TreatmentProtocolList = (props) => (
    <BQModelList {...props}>
        <TextField source="name" />
        <TextField source="numberOfTreatments" />
    </BQModelList>
)

const TreatmentProtocolEditor = props => {
    const classes = useStyles();
    const { isLoadingDictionaries, cache, language: { code: langCode } } = props

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Protocol',
        duplicates: props.duplicates
    }

    return (
        <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Exercise Protocol" />}>
            <BQSideBySide>
                <BQSection title="Exercise Protocol Info" language={props.language}>
                    <BQInput source="name" validate={validateString} className={classes.textInput} duplicates={duplicatesCheckData} />
                    <BQInput source="numberOfTreatments" type="number" validate={validateNumber} min={1} />
                </BQSection>
                <BQSection title="Stages in Protocol" language={props.language}>
                    <br />
                    <BQLabel label="Total session time: [source] minutes" source="totalSessionTime" />
                    {!isLoadingDictionaries &&
                        <ExerciseCategoriesComponent isLoadingDictionaries={isLoadingDictionaries} cache={cache} langCode={langCode}>
                            <ArrayInput label="Exercise Stages" source="treatmentStages">
                                <BQIterator filterBy={cache.treatmentStagesCache.map(stage => stage.id)} filterComparer="treatmentStageId" noNumbering>
                                    <ReferenceInput
                                        perPage={1000}
                                        label="Exercise Stage"
                                        source="treatmentStageId"
                                        reference="treatmentStages">

                                        <BQSelectInput validate={validate} optionText={(item) => getTranslatedName(item, langCode)} />

                                    </ReferenceInput>
                                    <BQInput type="number" source="treatmentStageDuration" label="Stage duration in minutes" validate={validateNumber} min={1} onChange={() => {

                                    }} />

                                    <BQDropDown source="type" choices={choicesTreatmentStageType} label="Stage Type" validate={validate} allowDuplicates />

                                    <ArrayInput label="Categories For Stage" source="formData.exerciseCategoriesForTreatmentStage" style={{ marginLeft: '-42px' }}>

                                        <BQSimpleIterator getItemLabel={() => ''} disableRemove disableAdd disableReordering>
                                            <ExerciseCategoryCheckbox langCode={langCode} />
                                            <ArrayInput label="Selected exercises" source="selectedExercises">
                                                <BQSimpleIterator>
                                                    <BQCheckbox source="isEnabled" label={(item) => getTranslatedName(item, langCode)} style={{ marginLeft: '16px' }} />
                                                </BQSimpleIterator>
                                            </ArrayInput>
                                        </BQSimpleIterator>

                                    </ArrayInput>
                                    <hr style={{ width: '100%' }} />
                                </BQIterator>
                            </ArrayInput>
                        </ExerciseCategoriesComponent>
                    }

                </BQSection>
            </BQSideBySide>
        </SimpleForm>
    )
}

const mutationTransform = async (data, setDuplicates, cache) => {
    const duplicates = await getDuplicates('TreatmentProtocol', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    data.treatmentStages = data.treatmentStages && data.treatmentStages.filter(stage => cache.treatmentStagesCache.some(cachedStage => cachedStage.id === stage.treatmentStageId)).map((stage, stageIndex) => {
        return {
            index: stageIndex,
            treatmentStageId: stage.treatmentStageId,
            treatmentStageDuration: stage.treatmentStageDuration,
            type: stage.type,
            exercisesCategories: stage.formData.exerciseCategoriesForTreatmentStage.filter(cat => cache.exercisesCategoriesCache.some(cachedCat => cachedCat.id === cat.exerciseCategoryId)).map((category, categoryIndex) => {
                const selectedExercisesIndexed = category.selectedExercises.filter(exercise => cache.exercisesCache.some(cachedExercise => cachedExercise.id === exercise.exerciseId)).map((exercise, exerciseIndex) => { return { index: exerciseIndex, isEnabled: exercise.isEnabled, exerciseId: exercise.exerciseId } })
                return {
                    index: categoryIndex,
                    exerciseCategoryId: category.exerciseCategoryId,
                    selectedExercisesIndexed
                }
            })
        }
    })
    data.treatmentStages = data.treatmentStages || []
    delete data.totalSessionTime
    data.deletedAt = data.deletedAt || 0
    Object.keys(data).filter(key => key.match(/_scan/)).forEach(key => delete data[`${key}`])
    return data
};

const TreatmentProtocolMutate = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const dataProvider = useContext(DataProviderContext);
    const [state, setState] = useState({ isLoadingDictionaries: true });
    useEffect(() => {
        getLists(dataProvider, true).then(data => {
            setState({
                isLoadingDictionaries: false,
                cache: data
            })
        })
    }, [dataProvider]);

    const stateRef = useRef()
    stateRef.current = state

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => mutationTransform(data, setDuplicates, stateRef.current.cache) }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps} >
                    <TreatmentProtocolEditor {...props} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>) :
                (<Edit {...editorProps} >
                    <TreatmentProtocolEditor {...props} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const TreatmentProtocolCreate = props => TreatmentProtocolMutate(true, props);

const TreatmentProtocolEdit = props => TreatmentProtocolMutate(false, props); 

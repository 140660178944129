/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    TextField,
    List,
    Datagrid,
    useInput,
    DataProviderContext,
    ReferenceField,
    useNotify,
} from 'react-admin';

import { BQPagination, BQSection, BQSideBySide } from './Generic/BQUI';
import { BQJsonField, BQSvgIcon, createLabelFromSource, FunctionField } from './Generic/bq-form-components';
import { useBQStyles } from '../themes';
import * as dayjs from 'dayjs'
import { bqAuthData } from '../utils/bq-auth';
import { BQIcons } from '../symbols';
import { Typography } from '@material-ui/core';



const createRefreshToken = () => (parseInt(Math.random() * 100)).toString()

const dateTimeFormat = 'YYYY/MM/DD HH:mm:ss'

const describeDBChange = (record) => {
    const { tableName } = record
    const objectName = tableName?.substring(0, tableName?.indexOf('-'))
    return `DB change (${objectName})`
}

const describeItemCreated = (record) => {
    const { tableName } = record
    const objectName = tableName?.substring(0, tableName?.indexOf('-'))
    return `Item was created (${objectName})`
}

const getDescriptionForEvent = (record) => {
    const { eventName, description } = record
    let value = description
    if (!value) {
        const parsedEventName = eventName.split(' ')[0]
        switch (parsedEventName) {
            case 'InitiateAuth':
                value = 'Login process started'
                break;
            case 'ForgotPassword':
                value = 'A user request to reset the password'
                break;
            case 'ConfirmForgotPassword':
                value = 'The password of the user was changed'
                break;
            case 'RespondToAuthChallenge':
                value = 'Login success'
                break;
            case 'UpdateCognitoUser':
                value = 'User data updated'
                break;
            case 'UpdateItem':
                value = describeDBChange(record)
                break;
            case 'ResetPasswordForUser':
                value = 'Password reset by Admin'
                break;
            case 'CreateCognitoUser':
                value = 'A user was created'
                break;
            case 'DisableCognitoUser':
                value = 'A user was disabled'
                break;
            case 'CreateItem':
                value = describeItemCreated(record)
                break;
        }
    }
    return value
}

export const AuditTrailList = (props) => {

    const dataProvider = React.useContext(DataProviderContext);
    const [auditTrailEventNameState, setAuditTrailEventNameState] = React.useState({
        isLoading: false
    })

    const [visibleFields, setVisibleFields] = useState({
        description: { visible: true },
        userName: { visible: true, label: 'User email' },
        userId: { visible: false, label: 'User id' },
        eventName: { visible: true },
        eventType: { visible: true },
        tableName: { visible: true },
        affectedUserId: { visible: false, label: 'Affected user id' },
        dataBefore: { visible: false },
        dataAfter: { visible: false },
        dataDiff: { visible: true },
        sourceOfChange: { visible: false },
    })

    if (!auditTrailEventNameState.eventNames) {
        dataProvider.getList('auditTrailEventNames', {
            pagination: { page: 1, perPage: 10000 },
            sort: {},
        }).then(response => {
            setAuditTrailEventNameState({
                eventNames: response.data.map(item => {
                    return { name: item, value: item }
                })
            })
        }).catch((error) => {
            console.error(error)
        })
    }

    return (
        <List
            id={`auditTrail_list`}
            {...props}
            bulkActionButtons={false}
            perPage="50"
            pagination={<BQPagination />}
            filters={[<BQAuditTrailFilter source="listAuditTrails.filter" label={''} alwaysOn collections={auditTrailEventNameState} />]}
        >
            <>
                <div style={{ marginTop: '0px' }}>
                    <BQSection title="Columns to display" style={{ maxWidth: '100%' }}>
                        <table>
                            <tr>
                                {Object.keys(visibleFields).map(field => {
                                    const fieldItem = visibleFields[`${field}`]
                                    return <td style={{ paddingRight: '32px' }}>
                                        <BQFilterCheckbox
                                            field={field}
                                            label={fieldItem.label}
                                            value={fieldItem.visible}
                                            onClick={(value) => {
                                                fieldItem.visible = value
                                                setVisibleFields({ ...visibleFields })
                                            }} />
                                    </td>
                                })}
                            </tr>
                        </table>
                    </BQSection>
                </div>
                <BQSection title={`Audit Trail Results`} style={{ maxWidth: '100%' }}>
                    <br />
                    <Datagrid>
                        <FunctionField source="timestamp" value={(val) => {
                            try {
                                return dayjs(new Date(parseInt(val))).format(dateTimeFormat)
                            }
                            catch {
                                return val
                            }
                        }} />
                        {Object.keys(visibleFields).filter(key => visibleFields[`${key}`].visible).map(field => {
                            switch (field) {
                                case 'userName':
                                    return (
                                        <ReferenceField
                                            link={false}
                                            label="User email"
                                            source="userId"
                                            reference="CognitoUsers">
                                            <TextField source="email" />
                                        </ReferenceField>
                                    )
                                case 'description':
                                    return (<FunctionField label={'Description'} value={(val) => getDescriptionForEvent(val)} />)
                                case 'dataBefore':
                                case 'dataAfter':
                                case 'dataDiff':
                                    return (
                                        <BQJsonField label={visibleFields[`${field}`].label} source={field} />
                                    )
                                default:
                                    return (<TextField source={field} label={visibleFields[`${field}`].label} style={{ fontSize: '14px' }} />)
                            }
                        })}
                    </Datagrid>
                </BQSection >
            </>
        </List >
    )
}

const BQAuditTrailFilter = (props) => {
    const notify = useNotify()
    const filterItem = useInput(props)
    const currentState = filterItem.input.value && JSON.parse(filterItem.input.value) || {}
    const [filterObject, setFilterObject] = useState(currentState)
    const classes = useBQStyles();

    const { collections } = props

    const search = () => {
        const filterQuery = {}
        const filterObjectKeys = Object.keys(filterObject)
        filterObjectKeys.map(key => {
            if (filterObject[`${key}`]?.toString()?.trim()) {
                switch (key) {
                    case 'from':
                    case 'to':
                        filterQuery[`${key}`] = new Date(filterObject[`${key}`]?.toString().trim()).getTime()
                        break;
                    default:
                        filterQuery[`${key}`] = filterObject[`${key}`]?.toString()?.trim()
                        break;
                }
            }
        })
        if (filterObject["from"] > (filterObject["to"] || Number.MAX_VALUE)) {
            notify('Date range is wrong. "From" value should be lower than "To" value', { type: 'warning' })
        }
        else if (!filterObjectKeys.filter(key => key !== 'from' && key !== 'to' && key !== 'refresh' && filterObject[`${key}`]?.toString()?.trim())?.length) {
            notify('Please use at least one filter (other than a time range)', { type: 'warning' })
        } else {
            filterQuery.refresh = createRefreshToken()
            filterItem.input.onChange(JSON.stringify(filterQuery))
        }
    }

    const clearSearch = () => {
        setFilterObject({})
        location = '/#/auditTrails'
        filterItem.input.onChange(JSON.stringify({ refresh: createRefreshToken() }))
        setTimeout(() => {
            const inputs = document.body.getElementsByTagName('input')
            for (const index in inputs) {
                const inputItem = inputs[index]
                if (inputItem.type === 'text' || inputItem.type === 'datetime-local') {
                    inputItem.value = ''
                }
            }
        }, 0)
    }

    return <div>
        <span style={{ lineHeight: '1.5em' }}>{`Generated at: ${dayjs().format(dateTimeFormat)}`}<br />{`By: ${bqAuthData.name} (${bqAuthData.email})`}</span>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <tr>
                <td>
                    <BQSection title="Filters" style={{ maxWidth: '100%' }}>
                        <BQSideBySide>
                            <table className={classes.BQSectionContent} title={'Filter'}>
                                <BQFilterInput {...props} placeholder=' ' autoComplete={null} filterObject={filterObject} source="from" type="datetime-local" />
                                <BQFilterInput {...props} placeholder=' ' autoComplete={null} filterObject={filterObject} setFilterObject={setFilterObject} source="userId" />
                                <BQFilterInput {...props} placeholder=' ' autoComplete={null} filterObject={filterObject} source="userName" label="User email" />
                            </table>
                            <table className={classes.BQSectionContent} title={'Filter'}>
                                <BQFilterInput {...props} placeholder=' ' autoComplete={null} filterObject={filterObject} source="to" type="datetime-local" />
                                <BQFilterDropDown {...props} placeholder=' ' autoComplete={null} filterObject={filterObject} source="eventName" items={collections?.eventNames} />
                                <BQFilterInput {...props} placeholder=' ' autoComplete={null} filterObject={filterObject} source="tableName" />
                            </table>
                        </BQSideBySide>
                    </BQSection>
                </td>
            </tr>

            <tr>
                <td style={{ margin: 'auto', textAlign: 'center' }}>
                    <div
                        id="btSearch"
                        className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" style={{
                            boxShadow: 'none'
                        }} onClick={() => search()}>Search</div>

                    <input
                        id="btClear"
                        className={`MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-50 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall MuiButton-contained ${classes.buttonReset}`}
                        type="reset"
                        onClick={() => {
                            clearSearch()
                        }} value="Clear" />
                </td>
            </tr>
        </table>
    </div>
}

const InputLayout = (props) => {
    const {
        id,
        label,
        style,
        source
    } = props
    const bqClasses = useBQStyles();
    return <tr className={bqClasses.textInputContainer}>
        <td className={bqClasses.inputLabel}>
            <span className={`${id}_label`}>
                {label || createLabelFromSource(source)}
            </span>
        </td>
        <td className={bqClasses.textInput}>
            <span style={{ ...(style || {}), minWidth: '100%' }}>
                {props.children}
            </span>
        </td>
    </tr>
}

const BQFilterDropDown = (props) => {
    const {
        id,
        items,
        onChange,
        style,
        filterObject,
        source
    } = props

    const bqClasses = useBQStyles();

    const [value, setValue] = useState(filterObject[`${source}`] || '')
    if (value !== filterObject[`${source}`]) {
        setValue(filterObject[`${source}`])
    }

    const inputComponent = <select
        id={`${id || source}_filter_input`}
        className={bqClasses.bqInputValue}
        value={value}
        onChange={(e) => {
            const select = e.target
            let value = select.options[select.selectedIndex].value || null
            setValue(value)
            filterObject[`${source}`] = value
            onChange && onChange(e)
        }}>
        <option value={''}>All Events</option>
        {items?.map(item => <option value={item.value}>{item.name}</option>)}
    </select>

    return <InputLayout {...props}>
        {inputComponent}
    </InputLayout>
}

const BQFilterInput = (props) => {
    const { id,
        maxLength,
        onChange,
        type,
        style,
        placeholder,
        filterObject,
        setFilterObject,
        source,
        label
    } = props
    const bqClasses = useBQStyles();


    let initialValue = null
    switch (source) {
        case 'from':
        case 'to':
            initialValue = (filterObject[`${source}`] && new Date(filterObject[`${source}`]))
            initialValue = initialValue && dayjs(initialValue).format('YYYY-MM-DDTHH:mm') || null
            break;
        default:
            initialValue = filterObject[`${source}`]
            break;
    }



    const [value, setValue] = useState(initialValue || '')
    if (value !== initialValue) {
        setValue(initialValue)
    }


    const inputComponent = <input id={`${id || source}_filter_input_${Math.random() * 1000}`}
        placeholder={placeholder}
        className={`${bqClasses.bqInputValue} ${id || source}_filter_input`}
        maxLength={maxLength}
        type={type}
        value={value}
        onBlur={(e) => {
            let v = e.target.value
            setValue(v)
            filterObject[`${source}`] = v
            setFilterObject && setFilterObject({ ...filterObject })
            onChange && onChange(v)
        }}
        onChange={(e) => {
            let v = e.target.value
            setValue(v)
            filterObject[`${source}`] = v
            setFilterObject && setFilterObject({ ...filterObject })
            onChange && onChange(v)
        }} />

    return <InputLayout {...props}>
        {inputComponent}
    </InputLayout>
}

const BQFilterCheckbox = ({ field, label, value, onClick }) => {
    const classes = useBQStyles();
    return <table onClick={() => onClick(!value)} className={classes.pointer}>
        <tr>
            <td className={classes.checkbox}>
                <div style={{ width: '16px', height: '16px' }} id={`${field}_checkbox`}>
                    <BQSvgIcon component={value ? BQIcons.checkbox_on : BQIcons.checkbox_off} />
                </div>
            </td>
            <td style={{ paddingTop: '12px' }}>
                {<Typography variant="caption" className={classes.index}
                    id={`${field}_label`}>{label || createLabelFromSource(field)}</Typography>}
            </td>
        </tr>
    </table>
}

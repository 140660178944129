/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    FormDataConsumer,
    REDUX_FORM_NAME,
    useInput
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form'

import { useForm } from 'react-final-form';
import { BQCheckbox } from './bq-form-components';
import * as deepcopy from 'deepcopy';
import { getTranslatedName } from '../../utils/textUtils';

// export const cache = {
//     treatmentProtocolsCache: null,
//     treatmentStagesCache: null,
//     exercisesCategoriesCache: null,
//     exercisesCache: null,
//     cognitoUsersCache: null
// }


export const ExcerciseType = {    
    DURING_TREATMENT: { type: 'DURING_TREATMENT', name: 'Exercises during treatment',  durationInMinutes: 40, arrayName: 'treatmentExercises' },
    POST_TREATMENT: { type: 'POST_TREATMENT', name: 'Exercises after treatment', durationInMinutes: 20, arrayName: 'postTreatmentExercises' }
}

export const ExerciseParent = {
    TREATMENT_PROTOCOL: 'TREATMENT_PROTOCOL',
    PATIENT: 'PATIENT'
}

export const DataTypes = {
    TREATMENT_PROTOCOLS: 'TREATMENT_PROTOCOLS',
    TREATMENT_STAGES: 'TREATMENT_STAGES',
    TREATMENT_CATEGORIES: 'TREATMENT_CATEGORIES',
    EXERCISES: 'EXERCISES'
}

export const TreatmentProtocolExercisesData = props => {
    if (props.record && props.record.treatmentExercises && props.record.treatmentExercises.items && !props.record.postTreatmentExercises) {
        const hasTreatments = !!(props.record.treatmentExercises && props.record.treatmentExercises.items);
        props.record.postTreatmentExercises = {
            items: hasTreatments && props.record.treatmentExercises.items.filter(item => item.deletedAt === 0 && item.exerciseType === ExcerciseType.POST_TREATMENT.type) || []
        }
        props.record.treatmentExercises = {
            items: hasTreatments && props.record.treatmentExercises.items.filter(item => item.deletedAt === 0 && item.exerciseType === ExcerciseType.DURING_TREATMENT.type) || []
        }
    }
    return ({ ...props.children })
}

export const getLists = async (dataProvider, withTreatmentProtocols) => {
    const listsResults = await Promise.all([
        withTreatmentProtocols && dataProvider.getList('treatmentProtocols', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }),
        dataProvider.getList('treatmentStages', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }),
        dataProvider.getList('exercisesCategorys', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }),
        dataProvider.getList('exercises', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }),
        dataProvider.getList('progressions', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        })
    ])
    const treatmentProtocols = withTreatmentProtocols && listsResults[0].data || null
    const treatmentStages = listsResults[1].data
    const exercisesCategories = listsResults[2].data
    const exercises = listsResults[3].data
    const progressions = listsResults[4].data

    return {
        treatmentProtocolsCache: withTreatmentProtocols && Object.keys(treatmentProtocols).map(key => treatmentProtocols[key]) || null,
        treatmentStagesCache: Object.keys(treatmentStages).map(key => treatmentStages[key]),
        exercisesCategoriesCache: Object.keys(exercisesCategories).map(key => exercisesCategories[key]),
        exercisesCache: Object.keys(exercises).map(key => exercises[key]),
        progressionsCache: Object.keys(progressions).map(key => progressions[key])
    }
}

export const ExercisesByTreatmentProtocol = props => {
    useInput(props)
    const { isLoadingDictionaries, cache } = props
    if (!isLoadingDictionaries) {
        const form = useForm();
        const formValues = form.getState().values;
    
        const treatmentProtocolId = formValues.treatmentProtocolId
        const treatmentProtocolFromCache = cache.treatmentProtocolsCache.find(tp => tp.id === treatmentProtocolId);
    
        if (treatmentProtocolFromCache) {
            if (!formValues.currentTreatmentProtocol || formValues.currentTreatmentProtocol.treatmentProtocolId !== treatmentProtocolId) {
                const treatmentStages = ((props.record && props.record.treatmentProtocolId === treatmentProtocolId && props.record.treatmentStages) || treatmentProtocolFromCache && treatmentProtocolFromCache.treatmentStages || []);
                const currentTreatmentProtocol = {
                    treatmentProtocolId,
                    treatmentStages: treatmentStages.filter(stage => {
                        const cachedStage = cache.treatmentStagesCache.find(cashedStage => cashedStage.id === stage.treatmentStageId);
                        if (cachedStage) {
                            stage.name = cachedStage.name
                            stage.nameTranslations = cachedStage.nameTranslations
                        }
                        return !!cachedStage
                    })
                }
                formValues.currentTreatmentProtocol = currentTreatmentProtocol
            }
        }
        form.change('currentTreatmentProtocol', formValues.currentTreatmentProtocol || { treatmentProtocolId: '', treatmentStages: [] });
    }
    return <></>
}

export const ExerciseCategoryCheckbox = props => {
    const inputValue = useInput(props)?.input?.value;
    const { name, nameTranslations, selectedExercises } = inputValue
    if (!selectedExercises) {
        return <></>
    }
    const form = useForm();
    return (<BQCheckbox id={props.source}  label={(item) => getTranslatedName(inputValue, props.langCode)}
        value={!selectedExercises?.some(c => !c.isEnabled)}
        indeterminate={selectedExercises?.some(c => c.isEnabled) && selectedExercises?.some(c => !c.isEnabled)}
        variant="h6"
        onChange={(event, value) => {
            const { readOnly, forceEdit, source } = props
            if (!readOnly || forceEdit) {
                selectedExercises?.forEach((item, index) => {
                    form.change(`${ source }.selectedExercises[${ index }].isEnabled`, value)
                });   
            }
        }}
    />)
}

export const ExerciseCategoriesComponent = (props) => {
    useInput(props)
    const { isLoadingDictionaries, cache, langCode } = props
    if (isLoadingDictionaries) {
        return <LinearProgress />
    }
    
    const { treatmentStagesContainer } = props
    const form = useForm();
    const values = form.getState().values;

    const treatmentStagesObject = (values[`${ treatmentStagesContainer }`] || values);
    treatmentStagesObject.treatmentStages && treatmentStagesObject.treatmentStages.forEach((stage, index) => {
        if (stage && (!stage.formData || stage.formData.id !== stage.treatmentStageId)) {
            const { treatmentStageId, exercisesCategories: recordExercisesCategories } = stage || {};
            const treatmentStageFromCache = deepcopy(cache.treatmentStagesCache.find(item => item.id === treatmentStageId));
            if (treatmentStageFromCache) {
                treatmentStageFromCache.exerciseCategoriesForTreatmentStage = treatmentStageFromCache.exerciseCategoriesForTreatmentStage || treatmentStageFromCache && treatmentStageFromCache.exerciseCategories
                && deepcopy(cache.exercisesCategoriesCache.filter(ec => treatmentStageFromCache.exerciseCategories.some(id => 
                    id === ec.id
                )).map(ec => {
                    const categoryFromRecord = recordExercisesCategories && recordExercisesCategories.find(rcat => rcat.exerciseCategoryId === ec.id)
                    return {
                        index: categoryFromRecord?.index || treatmentStageFromCache.exerciseCategories.indexOf(ec.id),
                        exerciseCategoryId: ec.id,
                        name: ec.name,
                        nameTranslations: ec.nameTranslations,
                        selectedExercises: ec.selectedExercises || ec.exercises && deepcopy(cache.exercisesCache.filter(e => ec.exercises.some(id => id === e.id)) || []).sort((a,b) => {
                            const indexA = ec.exercises.indexOf(a.id)
                            const indexB = ec.exercises.indexOf(b.id)
                            return indexA - indexB
                        }).map(ex => {
                            const selectedExercises = categoryFromRecord && (categoryFromRecord.selectedExercises && categoryFromRecord.selectedExercises.length !== 0 && categoryFromRecord.selectedExercises || categoryFromRecord.selectedExercisesIndexed)
                            const exerciseFromRecord = selectedExercises && selectedExercises.find(rex => (rex.exerciseId || rex) === ex.id);
                            const hasIsEnabledPropery = exerciseFromRecord && exerciseFromRecord && Object.keys(exerciseFromRecord).some(key => key === 'isEnabled');
                            const availableProgressions = ex.progressionsList?.map(item => cache.progressionsCache.find(cp => cp.id == item)).filter(item => item)
                            return {
                                name: ex.name,
                                nameTranslations: ex.nameTranslations,
                                exerciseId: ex.id,
                                secsInsteadOfReps: ex.secsInsteadOfReps,
                                orgprogressionsList: ex.progressionsList,
                                orgNumOfRepetitions: ex.numOfRepetitions,
                                orgNumOfSets: ex.numOfSets,
                                numOfRepetitions: exerciseFromRecord?.numOfRepetitions,
                                numOfSets: exerciseFromRecord?.numOfSets,
                                availableProgressions,
                                progressionsList: exerciseFromRecord?.progressionsList?.length && exerciseFromRecord.progressionsList || undefined,
                                index: exerciseFromRecord && exerciseFromRecord.index,
                                isEnabled: !exerciseFromRecord || (hasIsEnabledPropery && exerciseFromRecord.isEnabled || (!hasIsEnabledPropery && !!exerciseFromRecord))
                            }
                        }).sort((a,b) => a.index - b.index)
                    }
                }).sort((a,b) => a.index - b.index)) || []
            }
            if(!Object.keys(stage).some(key => key === 'type')) {
                form.change(`treatmentStages[${ index }].formData`, treatmentStageFromCache);
            } else {
                stage.formData = treatmentStageFromCache
            }
        }
    })
    if (!treatmentStagesObject.treatmentStages || treatmentStagesObject.treatmentStages.length === 0) {
        treatmentStagesObject.treatmentStages = []
    }

    treatmentStagesObject.treatmentStages.sort((a,b) => a.index - b.index)


    let totalSessionTime = 0;
    treatmentStagesObject.treatmentStages.map(item => {
        let currentDuration = 0;
        try {
            currentDuration = parseInt(item.treatmentStageDuration)
        }
        catch { 
            currentDuration = 0;
        }
        
        totalSessionTime += currentDuration
    })
    form.getState().values.totalSessionTime = totalSessionTime


    const childrenWithProps = React.Children.map(props.children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { record: props.record, form });
        }
        return child;
    });
    return childrenWithProps || <div></div>; 
}

export const ExerciseCategoryElement = formProps => {
    const dispatch = useDispatch()
    const form = useForm();
    let { scopedFormData } = formProps
    const { exerciseCategoriesForTreatmentStage } = scopedFormData.treatmentStage || {}
    return exerciseCategoriesForTreatmentStage && exerciseCategoriesForTreatmentStage.map(exercisesCategory => {
        let { selectedExercises } = exercisesCategory
        return (<p>
            <BQCheckbox
                style={{ color: '#2196f3' }}
                value={!selectedExercises?.some(c => !c.isEnabled)}
                indeterminate={selectedExercises?.some(c => c.isEnabled) && selectedExercises?.some(c => !c.isEnabled)}
                onChange={(event, value) => {
                    selectedExercises.forEach(item => item.isEnabled = value)
                    dispatch(change(REDUX_FORM_NAME));
                }}/>
            {exercisesCategory.exerciseCategoryName}
            <ul>
                {selectedExercises && selectedExercises.map(exercise => {
                    const {
                        id,
                        input,
                        isRequired,
                        meta: { error, submitError, touched },
                    } = useInput({ source: 'isEnabled' });
                    return (<li>
                        <BQCheckbox
                            style={{ color: '#2196f3' }}
                            value={exercise.isEnabled}
                            onClick={() => {
                                exercise.isEnabled = !exercise.isEnabled
                                dispatch(change(REDUX_FORM_NAME));
                            }}/>
                        {exercise.name}
                        <span style={{ fontSize: '0.8em' }}>&nbsp;{`(${ exercise.numOfRepetitions } repetitions)`}</span>
                    </li>)
                })}
            </ul>
        </p>)
    }) || (<div></div>)
}

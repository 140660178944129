import * as React from 'react';
import { ReferenceInput, useGetList, LinearProgress } from 'react-admin';
import { validate, validateNumber } from '../utils/validations';
import { BQSelectInput } from './Generic/bq-form-components';
import { BQDropDown } from './Generic/bq-input';

let ouItems = [];
export const setOU = (data) => {
    const ouItem = ouItems.find(item => item.id === data.organizationalUnitId);
    if (ouItem) {
        data.organizationalUnit = ouItem.name;
    }
}

export const OUSelectInput = (props) => {
    const { data: ouTemp, loading: isOUsLoading } = useGetList(
        'organizationalUnits',
        { page: 1, perPage: 1000 },
        { field: 'name', order: 'ASC' },
        { }
    );

    ouItems = Object.keys(ouTemp).map(key => ouTemp[key]);

    const { label } = props;
    
    return isOUsLoading ? <LinearProgress/> : (
        <ReferenceInput {...props}
            perPage={1000}
            label={`${ label ? label : 'Clinic' }`}
            source="organizationalUnitId"
            reference="organizationalUnits" >
            <BQDropDown source="organizationalUnitId" validate={validate} />
        </ReferenceInput>
    )
}
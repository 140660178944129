/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    required,
    Create,
    Edit,
    SimpleForm,
    ReferenceInput,
    AutocompleteArrayInput,
    Button,
    useMutation,
    BooleanField,
    useNotify,
    FormDataConsumer,
} from 'react-admin';
import { bqAuthData } from '../utils/bq-auth';
import { choicesRole } from '../utils/bq_enums';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { getDateTime } from '../utils/textUtils';
import { getDuplicates, validate, validateDuplicates, validateEmail, validatePhoneNumber, validateUsename } from '../utils/validations';
import { BQLabelWithInput, FunctionField } from './Generic/bq-form-components';
import { BQDropDown, BQInput } from './Generic/bq-input';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';

const validateOU = [required()];

const validateRoles = (roles) => {
    if (!roles || roles.length === 0) {
        return 'Required'
    }
    if (!checkAllowedRoleCombination(roles, [['Administrator', 'Operator']])) {
        return 'You cannot create a user with the combination of these roles'
    }
    return null
}

const checkAllowedRoleCombination = (roles, allowedCombinations) => {
    if (!roles || roles.length === 1) {
        return true
    }

    return roles.some(item => item === 'Super Administrator') ||
        allowedCombinations.some(combination =>
            roles.length === combination.length &&
            !roles.some(role => !combination.some(comb => role === comb)))
}

const cognitoUserTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('CognitoUser', data, ['email']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    delete data.organizationalUnitName
    try {
        data.role = JSON.stringify(data.role)
    }
    catch {

    }
    return data
};

export const CognitoUserList = (props) => {
    return <BQModelList {...props}
        disableDelete={(record) => !(record?.isActive)}
        deleteIconLabel="Deactivate user"
        perPage="25"
        nameField="email">
        <TextField source="email" />
        <FunctionField
            label="Role"
            source="role"
            value={(val) => {
                let rolesArray = val
                try {
                    rolesArray = JSON.parse(val)
                }
                catch {
                    return ''
                }
                const roleString = rolesArray?.map(role => choicesRole.find(r => r.id === role)?.name).reduce((acc, role) => `${acc}, ${role}`)
                return roleString
            }} />
        <TextField source="organizationalUnitName" label="Clinic" />
        <FunctionField label="Created" source="createDate" value={(val) => getDateTime(val)} />
        <FunctionField label="Last updated" source="lastModifiedDate" value={(val) => getDateTime(val)} />
        <BooleanField label="Activation status" source="isActive" />
    </BQModelList>
}

const ResetPasswordButton = ({ record }) => {
    const [resetPassword, { isResetting }] = useMutation()
    const [resetButtonState, setResetButtonState] = React.useState(true)
    const notify = useNotify()

    return resetButtonState ?
        <Button label="Activate / Reset password" onClick={() => {
            const recordToSend = { ...record }
            delete recordToSend.organizationalUnitName
            recordToSend.role = JSON.stringify(recordToSend.role)
            resetPassword({
                type: 'update',
                resource: 'CognitoUsers',
                payload: { id: recordToSend.id, data: { ...recordToSend, customAction: 'resendPassword' } }
            })
            setResetButtonState(false)
            notify('An email was sent to the user, please ask the user to follow the instructions in the email',
                { autoHideDuration: 6000, type: 'success' })
        }} disabled={isResetting} style={{ minWidth: '320px' }} />
        :
        <></>
};

export const CognitoUserEditor = (props) => {
    if (!Array.isArray(props?.record?.role)) {
        try {
            props.record.role = JSON.parse(props?.record?.role)
        }
        catch {
        }
    }

    const { isSuperAdmin, isMarketTargetAudience } = bqAuthData
    const validRoles = (isSuperAdmin ? choicesRole : choicesRole.filter(item => item.id !== 'Super Administrator')).filter(role => !isMarketTargetAudience || role.name !== 'Randomizer')
    props.record.role = props.record.role?.map(r =>
        choicesRole.find(cRole => {
            return cRole.id.toLowerCase() === r.toLowerCase()
        }).id
    );

    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'A user',
        duplicates: props.duplicates
    }

    return <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="User" nameField="email" />}>
        <BQSection title="User Info">
            <BQInput source="email" validate={validateEmail} duplicates={duplicatesCheckData} readOnly={props.record.email} />
            <BQInput label="Username" source="bqUsername" validate={validateUsename} placeholder="First name.Last name (or initials)" />
            <FormDataConsumer>
                {({ formData }) => {
                    const displayPhoneNumber = formData?.role?.some(role => role.match(/Super administrator/i))
                    return displayPhoneNumber && <BQInput type="phoneNumber" label="Phone number" source="phoneNumber" validate={validatePhoneNumber} placeholder="Phone number with country code" />
                }}
            </FormDataConsumer>

            <BQLabelWithInput>
                Role(s) *
                <AutocompleteArrayInput source="role" choices={validRoles} variant="outlined" validate={validateRoles} />
            </BQLabelWithInput>

            <ReferenceInput
                perPage={1000}
                label="Clinic"
                source="organizationalUnitId"
                reference="organizationalUnits" validate={validateOU}>
                <BQDropDown source="organizationalUnitId" validate={validate} />
            </ReferenceInput>
        </BQSection>
        {props?.record?.id &&
            <>
                <br />
                <br />
                <ResetPasswordButton {...props} />
            </>
        }
    </SimpleForm>
};

const CognitoUserMutate = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();

    const editorProps = { ...props, ...editorGlobalProps(), transform: (data) => cognitoUserTransform(data, setDuplicates) }
    if (isCreate) {
        editorProps.record = { isActive: true }
    }

    return (
        <div>
            {isCreate ?
                (<Create {...editorProps}>
                    <CognitoUserEditor redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...editorProps} >
                    <CognitoUserEditor redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

export const CognitoUserCreate = (props) => CognitoUserMutate(true, props)

export const CognitoUserEdit = (props) => CognitoUserMutate(false, props)



export const limitText = (text, maxLength) => {
    if (!text) {
        return ''
    }
    maxLength = maxLength || 64;
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text
}

export const getDateTime = (date) => {
    const dateObject = new Date(date);
    const timeElements = dateObject.toLocaleTimeString().match(/\d+|\w+/g);
    return `${dateObject.toLocaleDateString()} ${timeElements[0]}:${timeElements[1]} ${timeElements[3] || ''}`
}

export const replacePlaceholders = (text, formData, value) => {
    const matches = text && text.matchAll(/\[(.*?)\]/g);
    if (matches) {
        for (const match of matches) {
            const formValue = formData[`${match[1]}`]
            text = text.replace(match[0], match[1] === 'value' ? value : formValue)
        }
    }
    return text;
}

export const getTranslatedName = (item, langCode) => item.nameTranslations?.find(lang => lang.code === langCode)?.text || item.name || null
import { useNotify } from 'ra-core'
import { Auth } from 'aws-amplify';
import { clearBQAuthData } from './bq-auth';
import { endVideoMeeting, isVideoMeetingActive } from './bq-zoom';

export const bqCreateRedirection = 'list'
export const bqEditRedirection = 'list'
export const bqRowClickAction = 'edit'
export const bqMutationMode = 'pessimistic'

export const editorGlobalProps = () => {
    const notify = useNotify();
    return { mutationmode: bqMutationMode, undoable: false, onFailure: (e) => notify('Sorry, could not save data', 'error') }
}

const maxUserInactivityTime = 60000 * 20
let lastUserActivityTime = null;
let userInactivityChecker = null

export const resetSessionTimeout = () => {
    lastUserActivityTime = new Date().getTime()
    userInactivityChecker && clearInterval(userInactivityChecker)
    userInactivityChecker = setInterval(async () => {
        const now = new Date().getTime();
        const timeSinceLastUserActivity = now - (lastUserActivityTime || 0)
        if (timeSinceLastUserActivity >= maxUserInactivityTime) {
            if (!isVideoMeetingActive()) {
                endVideoMeeting()
                await Auth.signOut()
                clearBQAuthData()
                location.reload()
            } else {
                console.log('Video meeting is in progress, automatic logout is cancelled')
                resetSessionTimeout()
            }
        }
    }, 500)
}
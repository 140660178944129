/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react';
import {
    Children,
    cloneElement,
    isValidElement,
    useRef,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get'
import { DataProviderContext, FormDataConsumer, useInput, FormInput, InputHelperText, TextInput, NumberInput, ReferenceField, SelectField, SelectInput, useRecordContext, sanitizeFieldRestProps } from 'react-admin';
import { useForm } from 'react-final-form';
import { bq_theme, useBQStyles } from '../../themes';

import { Checkbox, SvgIcon, TextField } from '@material-ui/core';
import { BQIcons } from '../../symbols';
import { BQAutocompleteInput } from './BQAutoCompleteInput';
import { useDispatch } from 'react-redux';
import { languageSelected } from '../../redux/dispatchers/languageActions';
import { BQDropDown, BQInput, BQSimpleInput } from './bq-input';
import { cloneChild } from './UITools';
import ReactJson from 'react-json-view'
import PhoneInput from 'react-phone-number-input';

export const getLabelValue = (props) => {
    const { label, source } = props || {}
    switch (typeof label) {
        case 'function':
            const lastIndexOfDot = !source?.endsWith(']') && source?.lastIndexOf('.') || -1
            const record = useInput({ ...props, source: lastIndexOfDot > 0 ? source.substring(0, lastIndexOfDot) : source })?.input?.value;
            return label(record) || ' '
        default:
            return label || ' '
    }
}

export const createLabelFromSource = source => {
    if (!source) {
        return ''
    }
    const label = source.match(/([a-z]+)|([A-Z][a-z]+)/g).join(' ').trim().toLowerCase().replace('num ', 'number ');
    return label.charAt(0).toUpperCase() + label.slice(1);
}

export const BQSvgIcon = props => (<SvgIcon {...props} viewBox="-4 -3 24 24" />)

export const BQStaticIterator = props => {
    const {
        basePath,
        children,
        className,
        fields,
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        variant,
        margin,
        TransitionProps,
        defaultValue,
        filterBy
    } = props;

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
                ? defaultValue.length
                : 0
    );

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const classes = useBQStyles(props);
    const nodeRef = useRef(null);
    const records = get(record, source);
    const { spacing } = props
    return fields ? (
        <ul style={{ marginLeft: 0, marginTop: 0, marginBottom: 0 }}>

            <TransitionGroup component={null}>
                {fields.map((member, index) => {
                    if (filterBy && !filterBy.some(filterItem => filterItem === get(record, member))) {
                        return null
                    }
                    return (
                        <CSSTransition
                            nodeRef={nodeRef}
                            key={ids.current[index]}
                            timeout={500}
                            classNames="fade"
                            {...TransitionProps}
                        >
                            <li style={{ listStyleType: 'none', padding: 0, margin: 0, marginTop: 0 }}>
                                <section>
                                    {Children.map(
                                        children,
                                        (input, index2) => {
                                            if (!isValidElement(input)) {
                                                return null;
                                            }
                                            const {
                                                source,
                                                ...inputProps
                                            } = input.props;
                                            return (
                                                <FormInput
                                                    basePath={
                                                        input.props.basePath ||
                                                        basePath
                                                    }
                                                    style={{ marginTop: 0 }}
                                                    input={cloneElement(input, {
                                                        source: source
                                                            ? `${member}.${source}`
                                                            : member,
                                                        index: source
                                                            ? undefined
                                                            : index2,
                                                        label:
                                                            typeof input.props
                                                                .label ===
                                                                'undefined'
                                                                ? source
                                                                    ? `resources.${resource}.fields.${source}`
                                                                    : undefined
                                                                : input.props.label,
                                                        disabled,
                                                        ...inputProps,
                                                    })}
                                                    record={
                                                        (records &&
                                                            records[index]) ||
                                                        {}
                                                    }
                                                    resource={resource}
                                                    variant={variant}
                                                    margin={margin}
                                                />
                                            );
                                        }
                                    )}
                                </section>
                            </li>
                        </CSSTransition>
                    )
                })}
            </TransitionGroup>

        </ul>
    ) : null;
}

export const BQFormSection = props => {

    return (
        <FormDataConsumer {...props}>
            {(data) => {
                const getSource = data.getSource || props.getSource
                const scopedFormData = data.scopedFormData || props.scopedFormData
                const { formData } = data
                const form = useForm()
                const fixedScopedFormData = scopedFormData || formData;

                const cloneItem = (child) => {
                    const { source, sourceLabel, onChange, children, type } = child?.props || {};
                    const localChildren = mapChildren(children)
                    const component = React.cloneElement(child, {
                        ...child.props,
                        source: getSource(source),
                        record: fixedScopedFormData,
                        value: fixedScopedFormData[`${source}`],
                        children: localChildren,
                        sourceLabel: sourceLabel && fixedScopedFormData[`${sourceLabel}`],
                        onChange: (e, v) => {
                            let newValue = e.target.value;
                            if (typeof v !== 'undefined') {
                                newValue = v;
                            }
                            handleChange(newValue, source)
                            onChange && onChange()
                        }
                    })
                    return component
                }


                const mapChildren = (children) => {
                    const childrenToWorkWith = Array.isArray(children) ? children : [children]
                    return childrenToWorkWith.filter(item => item).map(child => cloneItem(child));
                }


                const handleChange = React.useCallback((newValue, source) => {
                    form.change(getSource?.(source) || source, newValue)
                });

                const childrenWithProps = mapChildren(props.children)

                return childrenWithProps
            }}
        </FormDataConsumer>
    )
}

export const BQLabelWithInput = (props) => {
    const { children, verticalAlign, onBlur, style, idClassName, ...rest } = props;
    const classes = useBQStyles();

    return <tr className={classes.textInputContainer} onBlur={onBlur}>
        <td className={classes.inputLabel}>
            <span style={style} id={`${idClassName}_label`}>
                {typeof children === 'string' ? children : children[0]}
            </span>
        </td>
        <td className={classes.textInput}>
            <span style={{ ...style, minWidth: '100%' }}>
                {typeof children === 'string' || !children[1] ? 'Second elemet is missing' : children[1]}
            </span>
        </td>
    </tr>
}

export const FunctionField = (props) => {
    const record = useRecordContext(props);
    const { source, value, valiant, ...rest } = props
    const sourceValue = source ? (record && record[`${source}`]) : record;
    const calculatedValue = value && value(sourceValue) || null;
    return <Typography valiant={valiant || 'caption'} {...sanitizeFieldRestProps(rest)} style={{ fontSize: '14px' }}>{calculatedValue}</Typography>
}

export const BQJsonField = (props) => {
    const { record, source, label, ...rest } = props
    let sourceValue = source ? (record && JSON.parse(record[`${source}`])) : record;
    const style = { fontSize: '14px' }

    if (source === 'dataDiff' && sourceValue) {
        const emptyKeys = sourceValue && Object.keys(sourceValue)?.filter(key => Object.keys(sourceValue?.[`${key}`])?.length === 0)
        emptyKeys?.forEach(key => {
            delete sourceValue[`${key}`]
        })
    }

    return sourceValue ?
        <ReactJson label={label} src={sourceValue} indentWidth={1} collapsed={true} enableClipboard={false} displayDataTypes={false} style={style} />
        :
        <Typography valiant={'caption'} {...sanitizeFieldRestProps(rest)} style={style}>{'N/A'}</Typography>
}

export const ActionField = (props) => {
    const { record, action } = props

    const children = React.Children.map(props.children, child => {
        return cloneChild(child)
    })

    return <div {...props} onClick={(e) => {
        e.stopPropagation()
        action(record)
    }}>
        {children}
    </div>
}

export const ConditionField = (props) => {
    const { record, hideIf, children } = props

    let conditionResult = true
    switch (typeof hideIf) {
        case 'function':
            conditionResult = !hideIf(record)
            break;
        default:
            conditionResult = !hideIf
            break;
    }

    return <div {...props}>
        {conditionResult && React.Children.map(children, child => {
            return cloneChild(child, props)
        })}
    </div>
}

export const LanguageSelection = (...props) => {
    const dp = React.useContext(DataProviderContext);
    const [list, setList] = React.useState([]);
    const dispatch = useDispatch()

    React.useEffect(() => {
        dp.getList('languages', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setList(data);
        })
    }, [dp]);

    const onChange = (inputCode) => {
        const selectedLanguage = list.find(item => item.code === inputCode)
        const { name, code } = inputCode || {}
        dispatch(languageSelected({ name, code }));
    }

    return <div style={{ minWidth: '384px' }}>
        <BQDropDown id="language" {...props} onChange={onChange} optionValue="code" label={null} choices={list} />
    </div>
}

export const TranslationInput = (props) => {
    const { language, label, source, validate, duplicates, multiline } = props
    const { code: langCode } = language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'
    return isEnglish ? <BQInput source={source} validate={validate} multiline={multiline} duplicates={duplicates} /> : <FormDataConsumer {...props}>
        {({ formData, scopedFormData, getSource }) => {
            const translationfieldSource = `${source}Translations`
            if (!formData[`${translationfieldSource}`]) {
                formData[`${translationfieldSource}`] = []
            }
            const translationsField = formData[`${translationfieldSource}`]
            let indexInTranslation = translationsField?.findIndex(item => item.code === langCode)
            if (indexInTranslation === -1) {
                translationsField.push({ code: langCode, text: null })
                indexInTranslation = 0
            }
            const translationSource = `${source}Translations[${indexInTranslation}].text`
            return <BQInput label={label || createLabelFromSource(source)} multiline={multiline} source={translationSource} validate={validate} />
        }
        }
    </FormDataConsumer>
}

export const BQLabel = props => {
    const { id, source, variant, style } = props
    const labelValue = getLabelValue(props) || ''
    const inputData = useInput(props)
    let value = props.value || inputData?.input?.value
    return <Typography id={id || source} variant={variant || 'h6'} style={{ ...bq_theme.labelStyle, ...(style || {}) }}>{labelValue && labelValue?.replace(/\[source\]/ig, value) || value}</Typography>
}

export const BQCheckbox = props => {
    const form = useForm()
    const classes = useBQStyles(props);

    const { id, source, sourceLabel, indeterminate, style, readOnly, forceEdit, variant } = props
    const idClassName = id || source
    const lableValue = getLabelValue(props)
    const {
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput(props);

    const parsedLabelSource = `${((source?.match(/(.*?)\.\w+$/)?.[1]) + '.') || ''}${sourceLabel}`

    let labelFromData = null

    if (parsedLabelSource) {
        const {
            input: labelInput,
        } = useInput({ ...props, source: parsedLabelSource || source });
        labelFromData = labelInput.value
    }

    const value = source ? input?.value : (props.value !== undefined ? props.value : undefined)
    const inter = indeterminate || value === null || typeof value === 'undefined'
    const labelToUse = labelFromData || lableValue || sourceLabel || (typeof lableValue !== 'undefined' ? lableValue : createLabelFromSource(source));

    const checkboxProps = { ...props }

    if (readOnly && !forceEdit) {
        delete checkboxProps.onChange
    }

    return (
        <table onClick={(() => {
            if (source) {
                form.change(source, !value)
            }
            props.onClick && props.onClick()
        })} className={classes.pointer} style={{ ...(style || {}) }}>
            <tr>
                <td className={classes.checkbox}>
                    <Checkbox
                        id={`${idClassName}_checkbox`}
                        {...checkboxProps}
                        checkedIcon={<BQSvgIcon component={BQIcons.checkbox_on} />}
                        value={value}
                        checked={value}
                        indeterminate={inter}
                        icon={<BQSvgIcon component={BQIcons.checkbox_off} />}
                        indeterminateIcon={<BQSvgIcon component={BQIcons.checkbox_intermediate} />}
                    />
                </td>
                <td>
                    {<Typography variant={variant || 'caption'} className={!variant && classes.index}
                        id={`${idClassName}_label`}
                        helperText={
                            <InputHelperText
                                touched={touched}
                                error={error || submitError}
                                helperText={'Required'}
                            />
                        }>{labelToUse}</Typography>}
                </td>
            </tr>
        </table>
    )
}

export const BQSelectInput = props => {
    const form = useForm();
    const { label, source, required, sortBy, unsorted, validate, allowDuplicates, readOnly, forceEdit, duplicates, noAutocomplete, style } = props;
    const classes = useBQStyles(props);

    const idClassName = `${source || label}`
    const duplicateMessage = duplicates && duplicates.validate(duplicates.itemName || 'Item', source, duplicates.duplicates)

    const validateSelectInput = (value) => {
        const { values: formValues } = form.getState();

        const hasValue = (objPath) => {
            let pathBuilder = '';
            for (const item of objPath.split('.')) {

                pathBuilder += (pathBuilder ? '.' : '') + item
                if (typeof eval(pathBuilder) === 'undefined') {

                    return false;
                }
            }

            return true;
        }

        const isDuplicate = allowDuplicates ? false : value && form.getRegisteredFields().some(rf => {
            const fieldToCheck = `formValues.${rf}`;
            return rf !== source && hasValue(fieldToCheck) && eval(fieldToCheck) === value
        });
        return isDuplicate ? 'Duplicate value' : (required && !value && 'Required' || undefined);
    }


    const formatedLabel = (typeof label !== 'undefined' ? label : createLabelFromSource(source));
    const autocompleteProps = {
        ...props,
        sortBy: sortBy || { field: 'name', order: 'ASC' },
        unsorted,
        validate: (value) => validate && validate(value) || validateSelectInput(value),
        clearAlwaysVisible: true,
        resettable: true
    }

    const valueComponent = (readOnly && !forceEdit) ?
        (autocompleteProps.choices && <SelectField id={`${idClassName}_input`} {...autocompleteProps} /> || <ReferenceField {...autocompleteProps} ><TextField id={`${idClassName}_readOnly`} source="name" /></ReferenceField>)
        : (noAutocomplete ? <SelectInput id={`${idClassName}_input`} {...autocompleteProps} clearAlwaysVisible={false} resettable={false} /> : <BQAutocompleteInput id={`${idClassName}_input`} {...autocompleteProps} style={{ marginTop: '-64px' }} />)

    return formatedLabel ?
        <BQLabelWithInput style={style} id={`${idClassName}`}>
            {`${formatedLabel}${validate ? ' *' : ''}`}
            <div className={classes.valueLabel}>
                <span id={`${idClassName}_input`}>{valueComponent}</span>
                {duplicateMessage && <p id={`${idClassName}_error`} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense" style={{ position: 'absolute', marginTop: '-10px' }}>{duplicateMessage}</p>}
            </div>
        </BQLabelWithInput>
        :
        valueComponent
}

const setSoruceToChild = (child, parentSource) => {
    const childSource = child?.props?.source
    const source = `${parentSource}${childSource ? `.${childSource}` : ''}`

    if (child.type.name === BQSimpleIterator.name) {
        return React.cloneElement(child, { source })
    }

    let childChildren = null
    if (Array.isArray(child?.props?.children)) {
        childChildren = child.props.children.map(grandchild => {
            if (React.isValidElement(grandchild)) {
                return setSoruceToChild(grandchild, source)
            } else {
                return grandchild
            }
        })
    } else if (React.isValidElement(child?.props?.children)) {
        childChildren = setSoruceToChild(child.props.children, source)
    } else {
        childChildren = child?.props?.children
    }
    return React.cloneElement(child, { source, children: childChildren })
}

export const BQSimpleIterator = (props) => {
    const inputValue = useInput(props)?.input?.value
    const children = []
    if (Array.isArray(inputValue)) {
        inputValue.forEach((_, index) => {
            React.Children.forEach(props.children, child => {
                if (React.isValidElement(child)) {
                    const source = `${props.source}[${index}]`
                    const clonedChild = setSoruceToChild(child, source)
                    children.push(clonedChild)
                } else {
                    children.push(child)
                }
            })
        })
    } else if (inputValue) {
        React.Children.forEach(props.children, child => {
            if (React.isValidElement(child)) {
                const source = `${props.source}${child.props.source ? `.${child.props.source}` : ''}`
                children.push(React.cloneElement(child, { source }))
            } else {
                children.push(child)
            }
        })
    }

    return <>{children}</>
}

export const BQPhoneNumber = (props) => {
    const [value, setValue] = React.useState()
    const inputValue = useInput(props)?.input?.value
    console.log(value)
    return (
        <PhoneInput
            placeholder="Enter phone number"
            value={inputValue}
            onChange={setValue} />
    )
}

/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm
} from 'react-admin';
import { getDuplicates, validateDuplicates, validateString } from '../utils/validations';

import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { BQInput, FunctionField, TranslationInput } from './Generic/bq-form-components';
import { connect } from 'react-redux';

class ProgressionComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = ProgressionCreate
        } else if (isEdit) {
            ComponentView = ProgressionEdit
        } else {
            ComponentView = ProgressionList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(ProgressionComponent);

const ProgressionList = (props) => {
    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'
    return <BQModelList {...props}>
        <TextField source="name" />
        {!isEnglish && <FunctionField source="nameTranslations" label={`Name - ${langName}`} value={v => v?.find(t => t.code === langCode)?.text} />}
    </BQModelList>
}

const progressionTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Progression', data, ['name']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    data.deletedAt = data.deletedAt || 0
    Object.keys(data).filter(key => key.match(/_scan/)).forEach(key => delete data[`${key}`])
    return data
}

const ProgressionEditor = props => {
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Progression',
        duplicates: props.duplicates
    }

    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'


    return <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Progression" />}>
        <BQSection title="Progressions Info" language={props.language}>
            <TranslationInput {...props} source="name" validate={validateString} duplicates={duplicatesCheckData} />
        </BQSection>
    </SimpleForm>
}

const ProgressionMutation = (isCreate, props) => {
    const [duplicates, setDuplicates] = React.useState();
    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => progressionTransform(data, setDuplicates) }

    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <ProgressionEditor {...props} redirect={bqCreateRedirection} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <ProgressionEditor {...props} redirect={bqEditRedirection} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const ProgressionCreate = (props) => ProgressionMutation(true, props)

const ProgressionEdit = (props) => ProgressionMutation(false, props)

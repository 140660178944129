import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BQAuthenticator } from './components/auth/BQAuthenticator';
import { endVideoMeeting, initVideoClient } from './utils/bq-zoom';
import { resetSessionTimeout } from './utils/constants';


const baseError = console.error;
console.error = function filterWarnings(msg) {
    const supressedWarnings = ['Warning:', 'Material-UI'];
    try {
        if (!supressedWarnings.some(entry => msg && msg.includes(entry))) {
            baseError.apply(console, arguments);
        } else {
            console.warn(msg);
        }
    }
    catch {
        baseError.apply(console, arguments);
    }
};

ReactDOM.render(
    <React.StrictMode>
        <BQAuthenticator>
            <App />
            {
                setTimeout(() => {
                    initVideoClient()
                }, 500)
            }
        </BQAuthenticator>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.onbeforeunload = function (e) {
    endVideoMeeting()
};

window.onmousemove = function (e) {
    resetSessionTimeout()
}

window.onkeydown = function (e) {
    resetSessionTimeout()
}

/* eslint-disable no-undef */
import { API, graphqlOperation } from 'aws-amplify';
const queries = {
    ...require('../graphql/queries'),
}

export const detectDuplicates = async (table, currentObjectId, items) => {
    const result = await API.graphql(graphqlOperation(queries.listDuplicates, {
        filter: {
            table, currentObjectId, items
        }
    }));
    const resultsWithoutCurrentItem = result?.data?.listDuplicates?.items
    const isDuplicate = resultsWithoutCurrentItem?.length > 0
    return { isDuplicate, items: resultsWithoutCurrentItem }
}
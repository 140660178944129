/* eslint-disable react/prop-types */
import * as React from 'react';
import {
    TextField,
    Create,
    Edit,
    SimpleForm,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    FormDataConsumer,
    DataProviderContext
} from 'react-admin';
import { getDuplicates, validate, validateDuplicates, validateNumber, validateString } from '../utils/validations';

import { choicesSetType, exerciseInApp } from '../utils/bq_enums'
import { BQLabelWithInput, BQSelectInput, createLabelFromSource, FunctionField, TranslationInput } from './Generic/bq-form-components';
import { bqCreateRedirection, bqEditRedirection, editorGlobalProps } from '../utils/constants';
import { BQModelList, BQSection, BQToolbar } from './Generic/BQUI';
import { getTranslatedName, limitText } from '../utils/textUtils';
import { getLists } from './Generic/TreatmentProtocolExerciseData';

import { connect } from 'react-redux';
import { BQDropDown, BQInput } from './Generic/bq-input';

class ExerciseComponent extends React.Component {
    render() {
        const basePath = this.props.basePath
        const pathname = this.props.location.pathname
        const isCreate = pathname === (`${basePath}/create`)
        const isEdit = !isCreate && pathname.indexOf(`${basePath}/`) === 0
        let ComponentView = null
        if (isCreate) {
            ComponentView = ExercisesCreate
        } else if (isEdit) {
            ComponentView = ExercisesEdit
        } else {
            ComponentView = ExercisesList
        }
        return (<ComponentView {...this.props} />)
    }
}

const mapStateToProps = state => {
    return { language: state.langSelected.language };
}

export default connect(mapStateToProps, {
})(ExerciseComponent);

const ExercisesList = (props) => {
    const { language } = props
    const { code: langCode, name: langName } = language || {}
    const isEnglish = !language?.code || language?.code?.toLowerCase() === 'en'
    return <BQModelList {...props} perPage="5">
        <TextField source="name" />
        {isEnglish ? <FunctionField source="tip" value={v => limitText(v)} /> : <FunctionField source="nameTranslations" label={`Name - ${langName}`} value={v => v?.find(t => t.code === langCode)?.text} />}
        {isEnglish && <FunctionField source="description" value={v => limitText(v)} />}
        <TextField source="numOfRepetitions" label={createLabelFromSource('numOfRepetitions')} />
        <TextField source="numOfSets" label={createLabelFromSource('numOfSets')} />
    </BQModelList>
}


const exerciseTransform = async (data, setDuplicates) => {
    const duplicates = await getDuplicates('Exercise', data, ['name', 'idInApp']);
    setDuplicates(duplicates)
    if (duplicates?.length > 0) {
        return false
    }

    delete data.exerciseCategory
    data.equipmentList = data.equipmentList || [];
    data.deletedAt = data.deletedAt || 0;
    Object.keys(data).filter(key => key.match(/_scan/)).forEach(key => delete data[`${key}`])
    return data
}



const ExerciseEditor = props => {
    const duplicatesCheckData = {
        validate: validateDuplicates,
        itemName: 'Exercise',
        duplicates: props.duplicates
    }

    const { code: langCode, name: langName } = props.language || {}
    const isEnglish = !langCode || langCode.toLowerCase() === 'en'

    return (
        <SimpleForm {...props} autoComplete="off" warnWhenUnsavedChanges toolbar={<BQToolbar type="Exercise" />}>
            <BQSection title="Exercise Info" language={props.language}>
                <TranslationInput {...props} source="name" validate={validateString} />


                <BQDropDown
                    label="Id in App"
                    source="idInApp"
                    choices={exerciseInApp}
                    validate={validateString}
                    duplicates={duplicatesCheckData}
                />

                <TranslationInput {...props} multiline source="tip" />
                <TranslationInput {...props} multiline source="description" validate={validateString} />

                <BQDropDown
                    label="Units"
                    source="secsInsteadOfReps"
                    choices={choicesSetType}
                    validate={validate}
                    translateChoice={false}
                    noAutocomplete={true}
                />

                <FormDataConsumer {...props}>
                    {({ formData, scopedFormData, getSource }) => {
                        return <BQInput type="number" source="numOfRepetitions" label={`Number of ${formData.secsInsteadOfReps ? 'seconds' : 'repetitions'}`} validate={validateNumber} min={1} />
                    }
                    }
                </FormDataConsumer>
                <BQInput type="number" source="numOfSets" validate={validateNumber} min={1} />

                <BQLabelWithInput idClassName={'progressionsList'}>
                    Progressions
                    <ReferenceArrayInput
                        source="progressionsList"
                        reference="progressions">
                        <AutocompleteArrayInput
                            id="progressionsList_input"
                            label="" optionValue="id" variant="outlined"
                            choices={props?.cache?.progressionsCache}
                            optionText={(item) => getTranslatedName(item, langCode)}
                        />
                    </ReferenceArrayInput>
                </BQLabelWithInput>

                <BQLabelWithInput idClassName={'equipmentList'}>
                    Equipment to use
                    <ReferenceArrayInput
                        source="equipmentList"
                        reference="equipments">
                        <AutocompleteArrayInput label="" optionValue="id"
                            id="equipmentList_input"
                            optionText={(item) => getTranslatedName(item, langCode)}
                            variant="outlined"
                        />
                    </ReferenceArrayInput>
                </BQLabelWithInput>

            </BQSection>
        </SimpleForm>

    )
}
const ExerciseMutation = (isCreate, props) => {
    const { language } = props
    const [duplicates, setDuplicates] = React.useState();

    const dataProvider = React.useContext(DataProviderContext);
    const [state, setState] = React.useState({ isLoadingDictionaries: true });
    React.useEffect(() => {
        getLists(dataProvider, true).then(data => {
            setTimeout(() => setState({
                isLoadingDictionaries: false,
                cache: data
            }), 500)
        })
    }, [dataProvider]);

    const mutationProps = { ...props, ...editorGlobalProps(), transform: (data) => exerciseTransform(data, setDuplicates) }
    return (
        <div>
            {isCreate ?
                (<Create {...mutationProps}>
                    <ExerciseEditor language={language} redirect={bqCreateRedirection} {...state} duplicates={duplicates} />
                </Create>)
                :
                (<Edit {...mutationProps}>
                    <ExerciseEditor language={language} redirect={bqEditRedirection} {...state} duplicates={duplicates} />
                </Edit>)
            }
        </div>
    )
}

const ExercisesCreate = (props) => ExerciseMutation(true, props)

const ExercisesEdit = (props) => ExerciseMutation(false, props)

/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import { LinearProgress, useInput } from 'react-admin';
import { useBQStyles } from '../../themes';
import { useState } from 'react';
import { useForm } from 'react-final-form';
import { createLabelFromSource } from './bq-form-components';
import ClearIcon from '@material-ui/icons/Clear';
import { ArrowDropDown } from '@material-ui/icons';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import flags from 'react-phone-number-input/flags'

export const BQInput = (props) => {
    const {
        id,
        source,
        type,
        style,
        label,
        placeholder,
        validate,
        duplicates,
        readOnly,
        multiline,
        visible,
        min,
        max,
        maxLength
    } = props;

    const localValidate = (value, record, meta) => {
        if (!validate) {
            return null
        }
        let validationError = null
        if (Array.isArray(validate)) {
            validate.forEach(validateFunction => {
                const validationText = validateFunction(value, min, max)
                if (validationText && !validationError) {
                    validationError = validationText
                }
            })
        } else {
            return validate && validate(value, min, max)
        }
        return validationError
    }

    const inputData = useInput({ ...props, validate: readOnly ? null : localValidate })

    let duplicateMessage = duplicates?.itemName && duplicates?.validate(duplicates.itemName || 'Item', source, duplicates.duplicates)

    const errorMessage = (inputData.meta.submitFailed || inputData.meta.touched) && inputData.meta.error

    const bqClasses = useBQStyles(props);
    const inputClasses = []
    inputClasses.push(bqClasses.bqInputValue)
    if (errorMessage || duplicateMessage) {
        inputClasses.push(bqClasses.bqInputValueError)
    }

    const idClassName = `${id, source || label}`
    const inputClassName = inputClasses.reduce((acc, item) => `${acc} ${item}`)

    const localOnBlur = (event) => {
        inputData?.input?.onBlur && inputData.input.onBlur(event)
    }

    const form = useForm()
    const localOnChange = (event) => {
        const newValue = event?.nativeEvent?.target?.value
        form.change(source, newValue);
        inputData?.input?.onChange && inputData.input.onChange(event)
    }

    return <>
        <tr className={bqClasses.textInputContainer} onBlur={localOnBlur} onChange={localOnChange} style={visible === false ? { display: 'none', height: 0 } : {}}>
            <td className={bqClasses.inputLabel}>
                <span className={`${idClassName}_label`}>
                    {label || createLabelFromSource(source)} {validate ? ' *' : ''}
                </span>
            </td>
            <td className={bqClasses.textInput}>
                <span style={{ ...(style || {}), minWidth: '100%' }}>
                    {readOnly
                        ?
                        <div id={`${idClassName}_readOnly`} className={bqClasses.bqInputValueReadOnly}>{inputData?.input?.value}</div>
                        :
                        (multiline
                            ?
                            <textarea id={`${idClassName}_input`} className={inputClassName} placeholder={placeholder} {...inputData.input} style={{ height: '72px' }} />
                            : (type === 'phoneNumber'
                                ? <PhoneInput className={inputClassName} placeholder={`${placeholder || 'Enter phone number'}`} {...inputData.input} style={{ maxHeight: '26px' }} flags={flags} international />
                                : <input id={`${idClassName}_input`} placeholder={placeholder} className={inputClassName} {...inputData.input} maxLength={maxLength} />)
                        )}
                </span>
            </td>
        </tr>
        <ErrorSection id={`${idClassName}_error`} message={duplicateMessage || errorMessage} visible={visible} />
    </>
}

const ErrorSection = (props) => {
    const { id, visible } = props
    return <tr style={{ height: visible === false ? '0px' : '10px', marginBottom: '8px', display: visible === false ? 'none' : '' }}>
        <td></td>
        <td style={{ height: '32px' }}>
            <p id={id} className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense" style={{ marginTop: '-16px', color: 'red', fontSize: '12px' }}>{props.message}</p>
        </td>
    </tr>
}

export const BQDropDown = (props) => {
    const {
        id,
        visible,
        source,
        style,
        label,
        placeholder,
        validate,
        duplicates,
        optionText,
        optionValue,
        choices,
        disableValue,
        defaultValueSource,
        unsorted,
        readOnly,
        record,
        minWidth,
        borderless
    } = props;
    const inputData = useInput({ ...props, validate: readOnly ? null : props.validate })

    const idField = optionValue || 'id'

    const [isOpen, setIsOpen] = useState()
    const [filteredChoices, setFilteredChoices] = useState()
    const [dropdownPositionTop, setDropdownPositionTop] = useState()
    const [selectedIndex, setSelectedIndex] = useState()
    const [localValue, setLocalValue] = useState()


    let duplicateMessage = duplicates && duplicates.validate(duplicates.itemName || 'Item', source, duplicates.duplicates)


    const defaultValue = defaultValueSource && useInput({ ...props, source: defaultValueSource })?.input?.value

    const errorMessage = (inputData.meta.submitFailed || inputData.meta.touched) && inputData.meta.error || (props.loaded && inputData.meta.error === 'Associated reference no longer appears to be available.' && inputData.meta.error)

    const selectedValue = inputData?.input?.value

    const selectedItem = choices.find(item => item[`${idField}`] === selectedValue)
    const selectedDisplayValue = selectedItem && (optionText && optionText(selectedItem) || selectedItem?.name) || (selectedValue !== undefined && selectedValue !== null ? (typeof selectedValue !== 'object' && selectedValue) : undefined)
    const inputProps = { ...inputData.input }

    if (isOpen) {
        delete inputProps?.value
    } else {
        inputProps.value = selectedDisplayValue || defaultValue || ''
    }

    const bqClasses = useBQStyles(props);
    const inputClasses = []
    inputClasses.push(borderless ? bqClasses.bqInputValueBorderless : bqClasses.bqInputValue)
    if (errorMessage || duplicateMessage) {
        inputClasses.push(bqClasses.bqInputValueError)
    }

    const idClassName = `${id || source || label}`
    const inputClassName = inputClasses.reduce((acc, item) => `${acc} ${item}`)

    const localOnChange = (event) => {
        const filterValue = event?.nativeEvent?.target?.value?.toLowerCase()
        const fc = [...choices.map(item => {
            const itemValue = optionText && optionText(item) || item.name
            return { ...item, indexOfFilter: itemValue?.toLowerCase()?.indexOf(filterValue) }
        }).filter(item => item.indexOfFilter !== -1)]
        setFilteredChoices(fc)
    }

    const form = useForm()
    const onSelect = (selection, event) => {
        const newValue = optionValue && selection?.[`${optionValue}`] || (selection?.id !== undefined && selection?.id !== null ? selection.id : selection)
        if (source) {
            form.change(source, newValue != defaultValue ? newValue : undefined);
        } else {
            setLocalValue(optionText && optionText(selection) || selection?.name)
        }
        props.onChange && props.onChange(selection)
        // inputData?.input?.onChange && inputData?.input?.onChange(newValue)
    }

    let choicesToDisplay = [...(filteredChoices || choices)]
    if (!unsorted) {
        choicesToDisplay.sort((a, b) => {
            const sortByIndex = (a.indexOfFilter || 0) - (b.indexOfFilter || 0)
            if (sortByIndex !== 0) {
                return sortByIndex
            }
            const numberCompare = a.name - b.name;
            if (numberCompare !== 0) {
                return numberCompare
            }

            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1
            } else {
                return 0;
            }
        })
    }

    const onKeyDown = (e) => {
        let _selectedIndex = selectedIndex !== undefined ? selectedIndex : -1
        switch (e.code) {
            case 'ArrowDown':
                _selectedIndex += 1
                break;
            case 'ArrowUp':
                _selectedIndex -= 1
                break;
            case 'Enter':
                e.stopPropagation()
                e.preventDefault()
                setIsOpen(false)
                onSelect(choicesToDisplay[_selectedIndex])
                break;
        }
        _selectedIndex = Math.min((choicesToDisplay?.length || 0) - 1, Math.max(0, _selectedIndex))

        setSelectedIndex(_selectedIndex)
    }

    const labelToDisplay = label === undefined && createLabelFromSource(source) || label

    return <>
        <tr className={bqClasses.textInputContainer} style={visible === false ? { display: 'none', height: 0 } : {}}>
            {
                labelToDisplay && <td className={bqClasses.inputLabel}>
                    <span className={`${idClassName}_label`}>
                        {labelToDisplay} {validate ? ' *' : ''}
                    </span>
                </td>
            }
            <td className={bqClasses.textInput}>
                {
                    (!props.loading) ?
                        readOnly ?
                            <div id={`${idClassName}_readOnly`} className={bqClasses.bqInputValueReadOnly} style={style}>{inputProps.value}</div>
                            :
                            <span style={{ ...(style || {}), minWidth: '100%' }}>
                                <div style={{ position: 'relative' }} onBlur={() => {
                                    setIsOpen(false)
                                }} onKeyDown={onKeyDown}>
                                    <input id={`${idClassName}_input`} className={inputClassName} placeholder={placeholder} {...inputProps} style={minWidth ? { minWidth: `${minWidth}px` } : {}} {...(localValue && { value: localValue })} onClick={(e) => {
                                        const rect = e.target.getBoundingClientRect()
                                        setDropdownPositionTop(window.innerHeight - rect.y - rect.height < 256)
                                        setIsOpen(!isOpen)
                                    }} onChange={localOnChange} onBlur={null} />
                                    {
                                        !borderless && <ClearIcon id={`${idClassName}_clear`} className={bqClasses.dropdownClearIcon} onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                            setFilteredChoices(null)
                                            if (source) {
                                                form.change(source, null)
                                            } else {
                                                setLocalValue(null)
                                            }
                                        }} />
                                    }
                                    {isOpen && <BQDropDownItems dropdownPositionTop={dropdownPositionTop} selectedIndex={selectedIndex} choices={choicesToDisplay} optionText={optionText} optionValue={optionValue} setIsOpen={setIsOpen} isOpen={isOpen} defaultValue={defaultValue} onSelect={onSelect} />}
                                </div>
                            </span>
                        : <LinearProgress id={`${idClassName}_progress`} style={{ marginTop: '24px' }} />
                }
            </td>
        </tr>

        {!borderless && <ErrorSection id={`${idClassName}_error`} message={duplicateMessage || errorMessage} visible={visible} />}
    </>
}

const BQDropDownItems = (props) => {
    const { choices, selectedIndex, isOpen, setIsOpen, optionText, optionValue, defaultValue, onSelect, dropdownPositionTop } = props
    const bqClasses = useBQStyles()
    return (
        <div className={bqClasses.dropdownContainer} style={dropdownPositionTop ? { bottom: '36px' } : { top: '43px' }}>
            {choices.map((item, index) => {
                const value = optionText && optionText(item) || item.name
                return <div id={`option_${value}`} className={bqClasses.dropdownItem} style={index === selectedIndex ? { backgroundColor: 'rgb(240, 240, 240)' } : null} onMouseDown={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }} onClick={(e) => {
                    setIsOpen(!isOpen)
                    if (onSelect) {
                        onSelect(item, e)
                    }
                }}>
                    {value}
                    {
                        item.id == defaultValue && <div className={bqClasses.dropdownDefaultIcon}>default</div>
                    }
                </div>
            })}
        </div>
    )
}